import prClient from "../../../common/prClient";
import { sseSubscribe } from "../../../common/sse";

const projectController = {
	getAllBuilds: (queryParams) => {
		return prClient({
			method: "get",
			url: `/project${queryParams === undefined ? "" : "?" + queryParams}`
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				return error;
			});
	},
	searchBuilds: (searchKey) => {
		return prClient({
			method: "get",
			url: `/project/${searchKey}`
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				return error;
			});
	},
	getBuildUsername: async (buildId) => {
		return prClient({
			method: "post",
			url: `/build/${buildId}/user`
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				console.log("Get Build User Error: ", error);
				return error;
			});
	},
	subscribeProject: (projectIds) => {
		const event_url = `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
		return sseSubscribe(`${event_url}/subscribe/project/${projectIds}`);
	},
	deleteSession: () => {}
};

export default projectController;

import React from "react";
import ReactDOM from "react-dom";
import App from "./app";

//sentry error reporting
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { LoginContext } from "./contexts/login-context";
import { HelmetProvider } from "react-helmet-async";

import "./styles/main.css";

async function initialize() {
	let LoginDetails = process.env.NODE_ENV === "production" ? { status: false } : { status: true };
	if (process.env.NODE_ENV === "production") {
		Sentry.init({
			dsn: "https://65ca4bb5bd39438ca9bf2ed48c35714c@o914956.ingest.sentry.io/5854575",
			integrations: [new Integrations.BrowserTracing()],
			tracesSampleRate: 1.0
		});
	}

	ReactDOM.render(
		<React.StrictMode>
			<LoginContext.Provider value={{ LoginDetails }}>
				<HelmetProvider>
					<App />
				</HelmetProvider>
			</LoginContext.Provider>
		</React.StrictMode>,
		document.getElementById("root")
	);
}

initialize();

import React, { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import prClient from "../../../common/prClient";
import DeleteIcon from "../../../assets/images/delete.png";
import ShareIcon from "../../../assets/images/shareimage.png";

// components
import Breadcrumbs from "../../../components/breadcrumbs";
import Search from "../../../components/search";
import SearchSuggestions from "./search-suggestions";
import toast from "react-hot-toast";
import buildController from "../controllers/build-controller";

export const SubHeader = ({ buildName, buildId, backgroundClass, boxShadow, rounded, setDelete }) => {
	const { id } = useParams();
	const [showSugesstions, setShowSugesstions] = useState(false);
	const searchKey = useRef("");
	// eslint-disable-next-line
	const delayedSearch = useCallback(
		debounce((key) => getSuggestions(key), 500),
		[]
	);

	const handleSearch = (key) => {
		searchKey.current = key;
		if (key.length > 2) {
			return delayedSearch(key);
		} else {
			// hide suggestions component
			setShowSugesstions(false);
		}
	};

	const [suggestionsData, setSuggestionsData] = useState([]);

	const getSuggestions = (key) => {
		prClient({
			method: "get",
			url: `/build/${id}/sessions/${key}`
		}).then(
			(resp) => {
				if (searchKey.current.length > 2) {
					setShowSugesstions(true);
					setSuggestionsData(resp?.data[0]?.sessions || []);
				}
			},
			(reason) => {
				console.log(reason);
				//toast showing something went wrong
			}
		);
	};

	return (
		<>
			<div className={backgroundClass + (boxShadow === true ? "shadow-primary" : "") + (rounded === true ? " rounded-md" : "")}>
				<div className="space-y-4 mx-auto flex flex-wrap py-6 flex-col md:flex-row items-center justify-between">
					<div>
						<div className="flex">
							<Breadcrumbs crumbs={[{ name: buildName, id: buildId, type: "build" }]} />

							<button
								className="pl-3 transform hover:-translate-y-px"
								onClick={() => {
									setDelete(true);
								}}
							>
								<img src={DeleteIcon} className="h-6" alt="Delete" />
							</button>
						</div>
					</div>
					<div className="flex flex-row gap-3">
						<div className="relative">
							<Search handleSearch={handleSearch} />
							{showSugesstions ? <SearchSuggestions suggestionsData={suggestionsData} /> : ""}
						</div>
						<div className="relative">
							<button
								type="button"
								className=" md:ml-auto py-2 px-4 flex justify-center items-center rounded-md text-sm bg-foreground text-color-primary shadow-primary"
								title="Anyone with this link can view this Session"
								onClick={() => {
									buildController
										.getSharableLink(buildId)
										.then((data) => {
											const sharedUrl = window.location.origin + `/shared/build/${buildId}?sharetoken=${data.data.token}`;
											navigator.clipboard
												.writeText(sharedUrl)
												.then(() => {
													toast.success("Link Copied Successfully");
												})
												.catch((e) => {
													console.log(e);
													toast.error("Something went wrong");
												});
										})
										.catch((err) => {
											toast.error("Something went wrong");
										});
								}}
							>
								<img src={ShareIcon} className="h-4 mr-2" id="shareimage" alt="Share" />
								Share Link
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

SubHeader.propTypes = {
	sessionName: PropTypes.string,
	backgroundClass: PropTypes.oneOf(["bg-foreground", "bg-background"]),
	boxShadow: PropTypes.bool,
	rounded: PropTypes.bool
};

SubHeader.defaultProps = {
	sessionName: null,
	backgroundClass: "bg-background",
	boxShadow: false,
	rounded: false
};

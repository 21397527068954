import { useEffect, useState } from "react";
import useDebouncedCallback from "../../../../utils/use-debounced-callback";

const SLDP = ({ url }) => {
	const [screenHeight, setScreenHeight] = useState(window.innerHeight);
	useEffect(() => {
		function scriptLoaded() {
			var isPlayerEmpty = document.getElementById("player").innerHTML === "";
			if (isPlayerEmpty)
				window.sldpPlayer = window.SLDP.init({
					container: "player",
					stream_url: url,
					initial_resolution: "240p",
					buffering: 500,
					autoplay: true,
					controls: false,
					height: "38vh",
					width: "100%"
				});
		}

		scriptLoaded();
	});

	const handleResize = useDebouncedCallback(() => {
		setScreenHeight(window.innerHeight);
	}, 1000);

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return (_) => {
			window.removeEventListener("resize", handleResize);
		};
		// eslint-disable-next-line
	}, []);

	const resizeVideoPlayer = () => {
		let parentHeight = document.querySelector(".sessionMainContainer").offsetHeight;
		let calculatedHeight = parentHeight * (2 / 5) - 12;
		document.querySelector(".sldp_player_wrp").style.height = `${calculatedHeight}px`;
		document.querySelector(".sldp_player_wrp").style.width = "100%";
	};

	useEffect(() => {
		resizeVideoPlayer();
	}, [screenHeight]);

	return <div id="player"></div>;
};

export { SLDP };

const DateInput = ({ inputRef, InputProps, placeHolder, ...inputProps }) => {
	return (
		<div className="w-full rounded-md text-sm bg-foreground text-color-primary shadow-primary flex cursor-pointer ">
			<input className="w-full rounded p-2 focus:outline-none cursor-pointer" type="text" placeholder={placeHolder} value={inputProps.value} readOnly ref={inputRef} />
			<span className="self-center pr-2"> {InputProps?.endAdornment}</span>
		</div>
	);
};

export default DateInput;

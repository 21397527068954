import { useRef, useState } from "react";
import Search from "../../../components/search";
import SearchSuggestions from "./search-suggestions";
import useDebouncedCallback from "../../../utils/use-debounced-callback";
import projectController from "../controller/project-controller";

const ProjectSearch = () => {
	const [showSugesstions, setShowSugesstions] = useState(false);
	const [suggestionsData, setSuggestionsData] = useState([]);
	const searchKey = useRef("");

	const delayedSearch = useDebouncedCallback((key) => {
		getSuggestions(key);
	}, 500);

	const getSuggestions = (key) => {
		projectController
			.searchBuilds(key)
			.then((resp) => {
				setShowSugesstions(true);
				setSuggestionsData(resp?.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleSearch = (key) => {
		searchKey.current = key;
		if (key.length > 2) {
			return delayedSearch(key);
		} else {
			// hide suggestions component
			setShowSugesstions(false);
		}
	};
	return (
		<div className="w-52 relative self-center">
			<Search handleSearch={handleSearch} />

			{showSugesstions ? <SearchSuggestions suggestionsData={suggestionsData} /> : ""}
		</div>
	);
};

export default ProjectSearch;

import axios from "axios";
import { AXIOS_TIMEOUT_IN_SEC } from "../constants";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const AUTH_COOKIE_NAME = "PYPCLOUDY";

/**
 * Create an Axios Client with defaults
 */

const client = axios.create({
	baseURL: `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`
});

client.defaults.timeout = AXIOS_TIMEOUT_IN_SEC * 1000;

client.defaults.headers.common["Authorization"] = `Session ${cookies.get(AUTH_COOKIE_NAME)}`;

client.defaults.withCredentials = true;

/**
 * Request Wrapper with default success/error actions
 */

async function prClient(options) {
	try {
		const response = await client(options);
		return response;
	} catch (error) {
		console.error("Request Failed:", error.config);

		if (error.response) {
			// Request was made but server responded with something
			// other than 2xx
			console.error("Status:", error.response.status);
			console.error("Data:", error.response.data);
			console.error("Headers:", error.response.headers);
		} else {
			// Something else happened while setting up the request
			// triggered the error
			console.error("Error Message:", error.message);
		}

		throw new Error(error.response || error.message);
	}
}

export default prClient;

import React, { useState, useEffect, useRef, useCallback } from "react";
import { localTime } from "../../common/prCommon";
import { Card } from "../../components/card";
import InProgressSvg from "../../assets/images/in-progress.svg";
import CompletedSvg from "../../assets/images/completed.svg";
import { BUILD_STATUS, BUILD_STATUS_TEXT, SESSION_STATUS, SESSION_STATUS_HEX } from "../../constants";
import Table from "./components/table";
import SubHeader from "./components/sub-header";
import Breadcrumbs from "../../components/breadcrumbs";
import PageMeta from "../../components/page-meta";
import { DeleteModal } from "../../components/delete-modal";
import buildController from "../build/controllers/build-controller";
import { MdOutlineDeleteOutline } from "react-icons/md";
import MergeModal from "./components/merge-build";
import CompareModal from "./components/compare-builds";

const ProjectPage = () => {
	const [allBuilds, setAllBuilds] = useState([]);
	const [checkedBuilds, setCheckedBuilds] = useState([]);
	const [isDelete, setDelete] = useState(false);
	const [deleteDetails, setDeleteDetails] = useState({ status: false });
	const [loadingBuildDetailsTable, setLoadingBuildDetailsTable] = useState(false);
	const [showHelpStrip, setShowHelpStrip] = useState(true);
	const [dropdownOpen, setDropdownOpen] = useState(null);
	const [mergeData, setmergeData] = useState([]);
	const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
	const [isCompareModalOpen, setCompareModalOpen] = useState(false);
	const [comparisonData, setComparisonData] = useState([]);
	const dropdownRef = useRef(null);

	const hideHelpStrip = () => {
		setShowHelpStrip(false);
	};

	const toggleDropdown = useCallback(() => {
		setDropdownOpen(!dropdownOpen);
	}, [dropdownOpen]);

	const handleCompareClick = useCallback(() => {
		if (checkedBuilds.length < 2) {
			alert("Please select at least two builds to compare.");
			return;
		}

		buildController
			.compareBuilds({ buildIds: checkedBuilds })
			.then((resp) => {
				const {
					data: { data }
				} = resp;
				setComparisonData(data);
				setCompareModalOpen(true);
			})
			.catch((error) => {
				console.log("Error comparing builds: ", error);
			});
	}, [checkedBuilds]);

	const handleMergeClick = useCallback(() => {
		if (checkedBuilds.length < 2) {
			alert("Please select at least two builds to merge.");
			return;
		}

		buildController
			.mergeBuilds({ buildIds: checkedBuilds })
			.then((resp) => {
				const {
					data: { data }
				} = resp;
				setmergeData(data);
				setIsMergeModalOpen(true);
			})
			.catch((error) => {
				console.log("Error merging builds: ", error);
			});
	}, [checkedBuilds]);

	const handleCheckboxChange = (buildId) => {
		setCheckedBuilds((prevCheckedBuilds) => {
			if (prevCheckedBuilds.includes(buildId)) {
				return prevCheckedBuilds.filter((prevId) => prevId !== buildId);
			} else {
				return [...prevCheckedBuilds, buildId];
			}
		});
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setDropdownOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropdownRef]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Status",
				accessor: "status",
				cellWidth: "10%",
				disableSortBy: true,
				Cell: ({ row: { original } }) => (
					<>
						<div className="flex flex-col justify-center space-y-2">
							<img src={original.status === BUILD_STATUS.INPROGRESS ? InProgressSvg : CompletedSvg} className="h-10 self-center" alt="status" />
							<span className="self-center">{BUILD_STATUS_TEXT[original.status]}</span>
						</div>
					</>
				)
			},
			{
				Header: "Build Name",
				accessor: "name",
				disableSortBy: true,
				cellWidth: "18%",

				Cell: ({ row: { original } }) => (
					<>
						<span className="font-weight-bold text-base block">{original.name}</span>
						<div className="mt-2">
							<span className="text-color-secondary font-weight-light text-sm pr-2">ID:</span>
							<span className="text-color-primary font-weight-light text-sm">{original.id}</span>
						</div>
						<div className="flex flex-wrap mt-2 space-x-1">
							<div>
								<span className="text-color-secondary font-weight-light text-sm pr-2">Project:</span>
								<span className="text-color-primary font-weight-light text-sm">{original.project.name}</span>
							</div>
						</div>
					</>
				)
			},
			{
				Header: "Started at",
				accessor: "updatedAt",
				cellWidth: "8%",
				disableSortBy: true,
				Cell: ({ cell: { value } }) => {
					return value ? localTime(value) : "-";
				}
			},
			{
				Header: "Total",
				disableSortBy: true,
				className: "text-center",
				cellWidth: "5%",
				Cell: ({ row: { original } }) => (
					<span style={{ color: "blue" }} className="font-weight-normal text-base">
						{original.totalSessions || "-"}
					</span>
				)
			},
			{
				Header: "Completed",
				disableSortBy: true,
				className: "text-center",
				cellWidth: "8%",
				Cell: ({ row: { original } }) => (
					<span style={{ color: SESSION_STATUS_HEX[SESSION_STATUS.COMPLETED] }} className="font-weight-normal text-base">
						{original["completed"] || "-"}
					</span>
				)
			},
			{
				Header: "In Progress",
				disableSortBy: true,
				className: "text-center",
				cellWidth: "5%",
				Cell: ({ row: { original } }) => (
					<span style={{ color: SESSION_STATUS_HEX[SESSION_STATUS.INPROGRESS] }} className="font-weight-normal text-base">
						{original["in-progress"] || "-"}
					</span>
				)
			},
			{
				Header: "Pass",
				disableSortBy: true,
				className: "text-center",
				cellWidth: "5%",
				Cell: ({ row: { original } }) => (
					<span style={{ color: SESSION_STATUS_HEX[SESSION_STATUS.PASSED] }} className="font-weight-normal text-base">
						{original.pass || "-"}
					</span>
				)
			},
			{
				Header: "Failed",
				disableSortBy: true,
				className: "text-center",
				cellWidth: "5%",
				Cell: ({ row: { original } }) => (
					<span style={{ color: SESSION_STATUS_HEX[SESSION_STATUS.FAILED] }} className="font-weight-normal text-base">
						{original.fail || "-"}
					</span>
				)
			},
			{
				Header: () => (
					<div className="relative flex-shrink-0 z-100" ref={dropdownRef}>
						<button
							className={`flex items-center justify-center w-full py-2 text-white rounded hover:bg-opacity-90 transition-colors duration-200 truncate ${checkedBuilds.length < 2 ? "bg-gray-500 cursor-not-allowed" : "bg-blue-500"}`}
							onClick={toggleDropdown}
							disabled={checkedBuilds.length < 2}
						>
							<span className="truncate text-sm">Actions</span>
						</button>
						{dropdownOpen && (
							<div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-1000">
								<div className="py-2">
									<button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={handleMergeClick}>
										Merge
									</button>
									<button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={handleCompareClick}>
										Compare
									</button>
								</div>
							</div>
						)}
					</div>
				),
				disableSortBy: true,
				className: "text-center",
				cellWidth: "8%",
				accessor: "actions",
				Cell: ({ row }) => (
					<div className="flex items-center justify-center">
						<input
							type="checkbox"
							className="h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
							checked={checkedBuilds.includes(row.original.id)}
							onChange={() => handleCheckboxChange(row.original.id)}
						/>
						<button
							className="pl-3"
							onClick={(e) => {
								setDeleteDetails(JSON.parse(e.target.id));
								setDelete(true);
							}}
						>
							<MdOutlineDeleteOutline className="h-6 w-8 text-red-500" id={`{"id":"${row.original.id}","name":"${row.original.name}"}`} />
						</button>
					</div>
				)
			}
		],
		[dropdownOpen, checkedBuilds, handleCompareClick, handleMergeClick, toggleDropdown]
	);

	return (
		<>
			<DeleteModal isDelete={isDelete} setDelete={setDelete} deleteMethod={buildController.deleteBuild} name={deleteDetails?.name} id={deleteDetails?.id} type={"build"} />
			<PageMeta title="Project Dashboard" description="Project details" />
			<div className="bg-background px-6 py-6 mt-8">
				<div className="my-4 ">
					<Breadcrumbs />
					{showHelpStrip && (
						<div className="help-strip text-white text-sm py-2 text-center h-10 rounded mt-2">
							Not sure how to enable Progressive Reports for your Appium Automation?{" "}
							<a className="underline" target="_blank" rel="noreferrer" href="https://www.pcloudy.com/mobile-application-testing-documentation/manual-app-testing/progressive-report.php">
								{" "}
								Click here
							</a>{" "}
							for a Step-by-Step guideline.
							<span onClick={() => hideHelpStrip()} className="cursor-pointer float-right mr-3">
								&#x2715;
							</span>
						</div>
					)}
				</div>

				<SubHeader allBuilds={allBuilds} setAllBuilds={setAllBuilds} setLoadingBuildDetailsTable={setLoadingBuildDetailsTable} />
				<Card>
					<div className={` ${loadingBuildDetailsTable ? "opacity-30 pointer-events-none" : ""}  `}>
						{!allBuilds?.length ? <div className="text-sm text-center pt-6 h-screen max-h-650px">No data found.</div> : <Table columns={columns} data={allBuilds} />}
					</div>
				</Card>
				<MergeModal isOpen={isMergeModalOpen} onRequestClose={() => setIsMergeModalOpen(false)} data={mergeData} />
				<CompareModal isOpen={isCompareModalOpen} onRequestClose={() => setCompareModalOpen(false)} data={comparisonData} />
			</div>
		</>
	);
};

export default ProjectPage;

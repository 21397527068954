import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isNotEmpty } from "../utils/empty";

const EditSessionModal = ({ isOpen, onClose, sessionName: initialSessionName, sessionStatus: initialSessionStatus, handleUpdateSession }) => {
	const [sessionNewName, setSessionNewName] = useState("");
	const [sessionNewStatus, setSessionNewStatus] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	const [nameError, setNameError] = useState("");
	const history = useHistory();

	useEffect(() => {
		setSessionNewStatus(initialSessionStatus);
	}, [initialSessionStatus]);

	useEffect(() => {
		setSessionNewName(initialSessionName);
	}, [initialSessionName]);

	useEffect(() => {
		if (isNotEmpty(sessionNewName) && (sessionNewName !== initialSessionName || sessionNewStatus !== initialSessionStatus)) {
			setIsEditing(true);
		} else {
			setIsEditing(false);
		}
	}, [sessionNewName, sessionNewStatus, initialSessionName, initialSessionStatus]);

	const handleSessionNameChange = (e) => {
		const value = e.target.value;
		if (value.length < 1) {
			setNameError("Session name must be at least 1 character long");
		} else if (value.length > 100) {
			setNameError("Session name must be less than 100 characters long");
		} else {
			setNameError("");
		}
		setSessionNewName(value);
	};

	const handleSessionStatusChange = (e) => {
		const value = e.target.value;
		setSessionNewStatus(value);
	};

	const handleSend = async () => {
		if (sessionNewName.length < 1 || sessionNewName.length > 100) {
			return;
		}
		await handleUpdateSession({ name: sessionNewName, status: sessionNewStatus });
		onClose();
		history.go(0);
	};

	if (!isOpen) return null;

	return (
		<div className="custom-modal" style={{ fontFamily: "'Poppins', sans-serif" }}>
			<div className="fixed z-10 inset-0 overflow-y-auto flex justify-center items-center">
				<div className="absolute inset-0 bg-black opacity-50"></div>
				<div className="bg-white p-8 rounded-lg shadow-xl relative" style={{ width: "400px", maxWidth: "90vw" }}>
					<button onClick={onClose} className="absolute top-2 right-2 hover:bg-gray-300 text-gray-700 rounded-full p-1" style={{ top: "1.5rem", right: "1rem" }}>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</button>
					<h2 className="text-lg mb-6">Edit Session Details</h2>
					<div className="mb-6">
						<label htmlFor="sessionNewName" className="block mb-1 text-gray-700 text-md">
							Session name:
						</label>
						<textarea id="sessionNewName" value={sessionNewName} onChange={handleSessionNameChange} className="border border-gray-300 rounded px-3 py-1 w-full" rows="3" />
						{nameError && <p className="text-red-500 text-sm">{nameError}</p>}
					</div>
					<div className="mb-6">
						<label htmlFor="sessionNewStatus" className="block mb-1 text-gray-700 text-md">
							Session status:
						</label>
						<select
							id="sessionNewStatus"
							value={sessionNewStatus}
							onChange={handleSessionStatusChange}
							className="border border-gray-300 rounded px-3 py-1 w-full custom-select"
							style={{
								appearance: "none",
								backgroundColor: "white",
								backgroundImage:
									"url(\"data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='gray' d='M2 0L0 2h4zM2 5L0 3h4z'/%3E%3C/svg%3E\")",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "right 0.75rem center",
								backgroundSize: "8px 10px",
								borderColor: "#ccc",
								padding: "8px 12px"
							}}
						>
							<option value="pass" className="p-2">
								Passed
							</option>
							<option value="fail" className="p-2">
								Failed
							</option>
							<option value="completed" className="p-2">
								Completed
							</option>
						</select>
					</div>
					<div className="flex justify-end">
						<button onClick={onClose} className="text-gray-600 border border-gray-600 px-4 py-1 rounded mr-4">
							Cancel
						</button>
						<button
							onClick={handleSend}
							className={`text-white px-4 py-1 rounded ${!isEditing ? "bg-gray-400 cursor-not-allowed" : "bg-blue-700 hover:bg-blue-600 cursor-pointer"}`}
							disabled={!isEditing}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditSessionModal;

import PropTypes from "prop-types";

// utils
import { Line } from "react-chartjs-2";
import { timestampToDateTime } from "../../../common/prCommon";

const Appprofile = ({ appProfile, timestamp, profilingType, bgColor, borderColor }) => {
	//Convert timestap to date time
	let dateTimes = [];
	for (let time of timestamp) {
		dateTimes.push(timestampToDateTime(time));
	}
	const options = {
		scales: {
			yAxes: [
				{
					ticks: {
						beginAtZero: true
					}
				}
			]
		}
	};
	const data = {
		labels: dateTimes,
		datasets: [
			{
				label: profilingType,
				data: appProfile,
				fill: false,
				backgroundColor: bgColor,
				borderColor: borderColor
			}
		]
	};
	return (
		<>
			<div className="header">
				<h1 className="title">{profilingType} Chart</h1>
				<div className="links"></div>
			</div>
			<Line data={data} options={options} />
		</>
	);
};

Appprofile.propTypes = {
	appProfile: PropTypes.array,
	timestamp: PropTypes.array,
	profilingType: PropTypes.oneOf(["CPU", "MEMORY"]),
	bgColor: PropTypes.string,
	borderColor: PropTypes.string
};

Appprofile.defaultProps = {
	appProfile: "",
	timestamp: "",
	profilingType: "",
	bgColor: "",
	borderColor: ""
};

export default Appprofile;

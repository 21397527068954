import FilterSvg from "../../assets/images/filter.svg";
import UpArrowSvg from "../../assets/images/up-arrow.svg";

const FilterButton = ({ isFilterOpen, setFilterOpen }) => {
	return (
		<button
			className="flex items-center justify-center space-x-1 self-center rounded-md shadow-primary p-2 "
			onClick={(e) => {
				e.preventDefault();
				setFilterOpen(!isFilterOpen);
			}}
		>
			<img src={FilterSvg} className=" block" alt="filter" />
			<img src={UpArrowSvg} className={`block h-4 ${isFilterOpen ? "transform rotate-180" : ""}`} alt="up-arrow" />
		</button>
	);
};

export default FilterButton;

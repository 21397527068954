const isEmpty = (value) =>
	value === undefined || value === null || Number.isNaN(value) || (typeof value === "object" && Object.keys(value).length === 0) || (typeof value === "string" && value === "");

const isNotEmpty = (value) => {
	const result = value === undefined || value === null || Number.isNaN(value) || (typeof value === "object" && Object.keys(value).length === 0) || (typeof value === "string" && value === "");
	return !result;
};

const isAnyEmpty = (paramsArr) => !paramsArr.every(isEmpty);

export { isEmpty, isNotEmpty, isAnyEmpty };

const AppiumLogs = ({ endpoint, request, response }) => {
	return (
		<>
			<div className="flex items-center w-full mx-auto space-x-2 my-4 order-1">
				<p className="text-color-secondary font-weight-normal text-sm w-28  break-all place-self-start">Endpoint</p>
				<p className=" text-sm w-full break-all place-self-end text-gray-600">{endpoint}</p>
			</div>
			<div className="order-1 bg-background px-4 pb-6 pt-2 w-full">
				<span className=" px-3 py-1  rounded-sm text-sm font-weight-normal border-0 focus:outline-none focus:ring transition text-blue-700 bg-blue-100">Params</span>
				{Object.keys(request).map((key, index) => {
					return (
						<div key={index} className="flex items-center w-full  mx-auto space-x-2 mt-4">
							<p className="text-color-primary font-weight-bold text-sm w-28 break-all place-self-start">{key}</p>
							<p className=" text-xs w-full break-all place-self-end text-gray-600">
								<pre className="whitespace-pre-wrap">{JSON.stringify(request[key], null, 1)}</pre>
							</p>
						</div>
					);
				})}
			</div>
			<div className="mt-4"></div>
			<div className="order-1 bg-background px-4 pb-6 pt-2 w-full">
				<span className=" px-3 py-1  rounded-sm text-sm font-weight-normal border-0 focus:outline-none focus:ring transition text-blue-700 bg-blue-100">Result</span>
				{Object.keys(response).map((key, index) => {
					return (
						<div key={index} className="flex items-center w-full  mx-auto space-x-2 mt-4">
							<p className="text-color-primary font-weight-bold text-sm w-28 break-all place-self-start">{key}</p>
							<p className=" text-xs w-full break-all place-self-end text-gray-600">
								<pre className="whitespace-pre-wrap">{JSON.stringify(response[key], null, 1)}</pre>
							</p>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default AppiumLogs;

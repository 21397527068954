import React, { useState } from "react";
import PropTypes from "prop-types";

export const Tabs = ({ tabs }) => {
	const [selectedTab, setSelectedTab] = useState(0);
	let tabClass = "tab-button text-sm text-color-primary  py-4 px-4 block hover:text-primary-accent-color focus:outline-none font-weight-normal";
	let selectedTabClass = `${tabClass} border-b-2 border-primary-accent-color`;

	return (
		<>
			<div className=" bg-foreground border-opacity-60 border-b-2 relative w-full flex flex-wrap items-center flex-col sm:flex-row">
				{tabs.map((tab, index) => (
					<button
						key={index}
						className={selectedTab === index ? selectedTabClass : tabClass}
						onClick={(e) => {
							e.preventDefault();
							setSelectedTab(index);
						}}
					>
						{tab.name}
					</button>
				))}
			</div>

			<div
				className={tabs[selectedTab].name === "Text Logs" ? "tab-content mt-4 overflow-hidden" : "tab-content mt-4 overflow-auto"}
				style={{
					height: "calc(100% - 106px)"
				}}
			>
				{tabs[selectedTab].content()}
			</div>
		</>
	);
};

Tabs.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			content: PropTypes.func.isRequired
		})
	)
};

Tabs.defaultProps = {
	tabs: []
};

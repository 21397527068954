import { Fragment } from "react";
import { useHistory } from "react-router";
import HomeSvg from "../assets/images/home.svg";
import RightArrowSvg from "../assets/images/right-arrow.svg";

const Breadcrumbs = ({ crumbs }) => {
	const history = useHistory();
	return (
		<div className="inline-flex space-x-3">
			<span className="text-2xl self-center font-weight-normal text-color-secondary">
				<button onClick={() => history.push("/project")}>
					<img alt="Home" src={HomeSvg} />
				</button>
			</span>
			{crumbs === undefined || crumbs?.length === 0 ? <h1 className="text-2xl font-weight-normal">Home</h1> : ""}

			{crumbs?.map((crumb, index) => (
				<Fragment key={index}>
					<span className="text-2xl self-center font-weight-normal text-color-secondary">
						<img alt="right-arrow" src={RightArrowSvg} />
					</span>
					<button>
						<h1
							className={`text-2xl font-weight-normal ${index !== crumbs.length - 1 ? "text-color-secondary " : ""}`}
							onClick={() => {
								if (index === crumbs.length - 1) return;
								let url = crumb.type === "build" ? `/build/${crumb.id}` : `/session/${crumb.id}`;
								history.push(url);
							}}
						>
							{crumb.name}
						</h1>
					</button>
				</Fragment>
			))}
		</div>
	);
};

export default Breadcrumbs;

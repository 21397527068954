import { useEffect, useState } from "react";
import { PAGINATED_RECORDS_PER_PAGE } from "../../../constants";

const Pagination = ({ totalSessionsCount, getQueryParamsOfActiveFilters, getAndSetAllSessions }) => {
	const [paginationState, setPaginationState] = useState({
		skip: 0,
		take: totalSessionsCount < PAGINATED_RECORDS_PER_PAGE ? totalSessionsCount : PAGINATED_RECORDS_PER_PAGE
	});

	const handleNextPage = () => {
		const newSkip = paginationState.skip + PAGINATED_RECORDS_PER_PAGE >= totalSessionsCount ? paginationState.skip : paginationState.skip + PAGINATED_RECORDS_PER_PAGE;

		if (newSkip === paginationState.skip) return;

		setPaginationState((prevState) => ({
			...prevState,
			skip: newSkip
		}));
	};

	const handlePreviousPage = () => {
		const newSkip = paginationState.skip - PAGINATED_RECORDS_PER_PAGE < 0 ? 0 : paginationState.skip - PAGINATED_RECORDS_PER_PAGE;

		if (newSkip === 0 && paginationState.skip === 0) return;

		setPaginationState((prevState) => ({
			...prevState,
			skip: newSkip
		}));
	};

	useEffect(() => {
		if (getQueryParamsOfActiveFilters() === "") {
			let queryParam = `skip=${paginationState.skip}&take=${paginationState.take}`;
			getAndSetAllSessions(queryParam);
		} else {
			let queryParam = `${getQueryParamsOfActiveFilters()}&skip=${paginationState.skip}&take=${paginationState.take}`;
			getAndSetAllSessions(queryParam);
		}
		// eslint-disable-next-line
	}, [paginationState]);

	return totalSessionsCount <= PAGINATED_RECORDS_PER_PAGE || !Number.isInteger(totalSessionsCount) ? (
		""
	) : (
		<div className="flex ml-auto py-4">
			<span className="ml-auto self-center px-4 text-sm text-color-tertiary">
				{paginationState.skip + 1} - {paginationState.skip + PAGINATED_RECORDS_PER_PAGE > totalSessionsCount ? totalSessionsCount : paginationState.skip + PAGINATED_RECORDS_PER_PAGE} of{" "}
				{totalSessionsCount}
			</span>
			<div className="flex items-center w-min rounded-md shadow-primary self-center text-color-tertiary">
				<div className="p-2 hover:bg-gray-50">
					<span className="px-3 leading-3 text-2xl cursor-pointer transform hover:-translate-y-px" onClick={() => handlePreviousPage()}>
						&#129168;
					</span>
				</div>
				<span className="border-opacity-60 border-r-2 h-5"></span>
				<div className="p-2 hover:bg-gray-50">
					<span className="px-3 leading-3 text-2xl cursor-pointer transform hover:-translate-y-px" onClick={() => handleNextPage()}>
						&#129170;
					</span>
				</div>
			</div>
		</div>
	);
};

export default Pagination;

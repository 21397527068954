import { useEffect, useState } from "react";
import SearchSvg from "../assets/images/search.svg";

const Search = ({ handleSearch }) => {
	const [showClearSearchBtn, setShowClearSearchBtn] = useState(false);
	const [searchText, setSearchText] = useState("");

	const handleInput = (e) => {
		setSearchText(e.target.value);
	};

	const clearSearchInput = () => {
		setSearchText("");
	};

	useEffect(() => {
		handleSearch(searchText);
		if (searchText.length > 0) {
			setShowClearSearchBtn(true);
		} else {
			setShowClearSearchBtn(false);
		}
		// eslint-disable-next-line
	}, [searchText]);

	return (
		<div className="w-full rounded-md text-sm bg-foreground text-color-primary shadow-primary flex">
			<img src={SearchSvg} className="ml-2" alt="search" />
			<input className="w-full rounded p-2 focus:outline-none bg-foreground" type="text" placeholder="Search..." value={searchText} onChange={(e) => handleInput(e)} />
			{!showClearSearchBtn ? (
				""
			) : (
				<span className="cursor-pointer self-center px-2 text-color-secondary" onClick={() => clearSearchInput()}>
					&times;
				</span>
			)}
		</div>
	);
};

export default Search;

import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import Exclamation from "../assets/images/exclamation.png";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)"
	}
};
Modal.setAppElement("#root");

export const DeleteModal = ({ isDelete, setDelete, deleteMethod, name, id, type, prevId }) => {
	let history = useHistory();
	return (
		<Modal isOpen={isDelete} style={customStyles} contentLabel="Delete Modal">
			<div className="flex justify-evenly">
				<img src={Exclamation} className="w-12 mr-4" alt="Exclamation" />
				<br />
				{/* <span> */}
				<div>
					<p className="font-bold text-center">
						Delete the {type} "{name}"?
					</p>
					<p className="text-sm text-gray-700 mt-1 text-center">
						Are you sure you want to delete the {type} "{name}"?
					</p>
				</div>
			</div>
			<div className="flex justify-center mt-4 ml-16">
				<button className="border-2 border-blue-500 p-2 hover:bg-blue-500 mr-4" onClick={() => setDelete(false)}>
					Cancel
				</button>
				<button
					className="border-2 p-2 border-blue-500 hover:bg-blue-500 ml-4"
					onClick={async () => {
						deleteMethod(id)
							.then((resp) => {
								type === "session" ? history.push(`/build/${prevId}`) : history.push("/");
							})
							.catch((error) => {
								console.log(error);
								setDelete(false);
								//Toast something went wrong
							});
					}}
				>
					Delete
				</button>
			</div>
		</Modal>
	);
};

import React, { useEffect, useState, useRef } from "react";
import ndjsonStream from "can-ndjson-stream";
import { fetchStream } from "../../common/fetchStream";
// components
import { SubHeader } from "./components/sub-header";
import { Tabs } from "../../components/tabs";
import { Card } from "../../components/card";
import NoDataFound from "../../components/no-data-found";
import Logs from "./components/logs";
import Appprofile from "./components/appprofile";
import sessionController from "./controllers/session-controller";
import SessionDetails from "./components/session-details";
import PageMeta from "../../components/page-meta";
import SessionVideo from "./components/video";
import SessionInfo from "./components/session-info";
import { DeleteModal } from "../../components/delete-modal";

// UTILS
import { SESSION_STATUS } from "../../constants";
import TextLogs from "./components/text-logs";
import { isNotEmpty } from "../../utils/empty";

/* eslint-disable react-hooks/exhaustive-deps */

const SessionPage = ({ match, isShared = false }) => {
	const [session, setSession] = useState({});
	const sessionFound = Object.keys(session).length > 0;
	const isS3LogAvailable = sessionFound ? isNotEmpty(session.eventLogUrl) : null;

	const [sessionDuration, setSessionDuration] = useState("-");
	const [sessionUser, setSessionUser] = useState("-");

	const [cpuAppProfile, setCpuAppProfile] = useState([]);
	const [memAppProfile, setMemAppProfile] = useState([]);

	const [serverEvent, setServerEvent] = useState({});

	const [isDelete, setDelete] = useState(false);

	const [textLogs, setTextLogs] = useState([]);
	const [updateLog, setUpdateLog] = useState({});

	const logsInitialized = useRef(false);
	const endReached = useRef(false);
	const listening = useRef(false);
	const virtuoso = useRef(null);
	const didMount = useRef(false);

	const lastEventOffset = textLogs.length > 0 ? textLogs[textLogs.length - 1]?.createdAt : null;

	const params = Object.fromEntries(new URLSearchParams(window.location.search));
	const shareToken = isShared ? (params.hasOwnProperty("sharetoken") ? params.sharetoken : "") : "";
	const sessionId = match.params.id;

	/*
	async function setupAutoScroll() {
		if (!endReached.current) {
			if (textLogs.length && virtuoso.current) {
				virtuoso.current.scrollToIndex({
					index: textLogs.length - 1,
					align: "end",
					behavior: "instant"
				});
			}
		}
	}
	useEffect(() => {
		setupAutoScroll();
	}, [textLogs]);
 */

	const getMoreLogs = () => {
		if (sessionFound && session.status !== SESSION_STATUS.INPROGRESS) {
			console.debug("I will get more logs");
			setUpdateLog({
				data: "update:textlog"
			});
		}
	};

	async function getEventLogsFromDb() {
		if (endReached.current) {
			return;
		}
		const { data } = await sessionController.getTextLogs(sessionId, isShared, shareToken, lastEventOffset, 2);
		if (data.length === 0) {
			endReached.current = true;
		} else {
			setTextLogs((prev) => {
				const prevLogSize = prev.length;
				const currLogSize = data.length;
				if (prevLogSize === 0 || (prevLogSize && prev[prevLogSize - 1]?.createdAt !== data[currLogSize - 1]?.createdAt)) {
					return prev.concat(data);
				} else {
					return prev;
				}
			});
		}
	}

	async function streamEventLogFromS3(eventLogUrl) {
		const response = await fetchStream(eventLogUrl);
		const stream = ndjsonStream(response.body);
		const streamReader = stream.getReader();

		const appendLogs = (logs) => setTextLogs((prev) => prev.concat(logs));
		let eventBuffer = [];
		let res = await streamReader.read();

		while (!res || !res.done) {
			eventBuffer.push(res.value);
			if (eventBuffer.length === 1000) {
				appendLogs(eventBuffer);
				eventBuffer = [];
			}
			res = await streamReader.read();
		}
		if (eventBuffer.length > 0) {
			appendLogs(eventBuffer);
			eventBuffer = [];
		}
	}
	async function streamEventLogFromLogCollector({ sessionId, cloudUrl }) {
		const response = await fetchStream(`${cloudUrl}/logcollector/api/v1/session/${sessionId}/events/all`, false);
		const stream = ndjsonStream(response.body);
		const streamReader = stream.getReader();

		const replaceLogs = (logs) => setTextLogs(logs);
		let allEvents = [];
		let res = await streamReader.read();

		while (!res || !res.done) {
			if (!res.value.hasOwnProperty("statusCode")) {
				allEvents.push(res.value);
			}

			res = await streamReader.read();
		}
		replaceLogs(allEvents);
	}

	function setupLiveEvents({ cloudUrl }) {
		const appendLog = (log) => setTextLogs((prev) => prev.concat(log));

		const sessionEvents = sessionController.subscribeToSessionEvents(sessionId, cloudUrl);

		sessionEvents.addEventListener(`appium:${sessionId}:log`, (e) => {
			const log = JSON.parse(e.data);
			appendLog([log]);
		});

		// sessionEvents.sessionEvents.onmessage = (e) => {};
		sessionEvents.onerror = (err) => {
			console.error("EventSource failed:", err);
		};
		return () => {
			console.log("closing sse");
			sessionEvents.close();
		};
	}

	useEffect(() => {
		if (didMount.current) {
			if (isS3LogAvailable === false) {
				getEventLogsFromDb();
			}
		} else {
			didMount.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateLog]);

	useEffect(() => {
		if (sessionFound && !logsInitialized.current) {
			logsInitialized.current = true;

			if (session.status === SESSION_STATUS.INPROGRESS) {
				if (session.cloud_url !== null) {
					streamEventLogFromLogCollector({ sessionId, cloudUrl: session.cloud_url });
					const cleanupFn = setupLiveEvents({ cloudUrl: session.cloud_url });
					return cleanupFn;
				} else {
					console.error("cloud_url is empty");
				}
			} else if (session.status !== SESSION_STATUS.INPROGRESS) {
				if (isS3LogAvailable === true) {
					console.debug("I will stream from s3");
					streamEventLogFromS3(session.eventLogUrl);
				} else {
					console.debug("I will get logs from db");
					getEventLogsFromDb();
				}
			}
		}
	}, [session]);

	async function setupSessionInfo() {
		try {
			const { data } = await sessionController.getSessionById(sessionId, isShared, shareToken);
			setSession(data);
			setupOtherInfo(data);
		} catch (error) {
			console.error(error);
		}
	}

	async function setupOtherInfo(sessionDetails) {
		if (sessionDetails) {
			if (sessionDetails.status !== SESSION_STATUS.INPROGRESS) {
				sessionController
					.getSessionDuration(sessionId, isShared, shareToken)
					.then((resp) => {
						setSessionDuration(resp.data.duration);
					})
					.catch(console.error);
			}
			if (sessionDetails.cpuLog)
				sessionController
					.getAppProfileData(sessionDetails.cpuLog)
					.then((resp) => {
						if (resp) setCpuAppProfile(resp);
					})
					.catch(console.error);
			if (sessionDetails.memLog)
				sessionController
					.getAppProfileData(sessionDetails.memLog)
					.then((resp) => {
						if (resp) setMemAppProfile(resp);
					})
					.catch(console.error);
			if (sessionDetails.userId) {
				sessionController
					.getUsername(sessionId, isShared, shareToken)
					.then((resp) => {
						if (sessionUser === "-" && resp.data && resp.data?.username) setSessionUser(resp.data.username);
					})
					.catch(console.error);
			}
		}
	}
	useEffect(() => {
		setupSessionInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serverEvent]);

	useEffect(() => {
		if (listening.current === false && !isShared) {
			const sessionId = match.params.id;
			const events = sessionController.subscribeSession(sessionId);
			events.onmessage = (event) => {
				if (event.data !== "keep-alive") {
					if (event.data === "update") {
						setServerEvent(event);
					}
				}
			};
			listening.current = true;
			return () => {
				events.close();
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const sessionLogsTabs = [
		{
			name: "Text Logs",
			content: () => {
				return textLogs ? (
					<div className="text-sm text-color-secondary">
						<TextLogs logs={textLogs} getMoreLogs={getMoreLogs} scrollRef={virtuoso} isShared={isShared} shareToken={shareToken} />
					</div>
				) : (
					<NoDataFound msg="Please wait for the logs to be available" />
				);
			}
		},
		{
			name: "Device Logs",
			content: () => {
				return session.status !== SESSION_STATUS.INPROGRESS && session.deviceLogsUrl !== null ? (
					<Logs sessionId={session.id} logURL={session.deviceLogsUrl} logType="device" />
				) : (
					<NoDataFound msg="Please wait for the logs to be available" />
				);
			}
		},
		{
			name: "Appium Logs",
			content: () => {
				return session.status !== SESSION_STATUS.INPROGRESS && session.appiumLogsUrl !== null ? (
					<Logs sessionId={session.id} logURL={session.appiumLogsUrl} logType="appium" />
				) : (
					<NoDataFound msg="Please wait for the logs to be available" />
				);
			}
		},
		{
			name: "App Profiling",
			content: () => {
				return session.status !== SESSION_STATUS.INPROGRESS && session.cpuLog !== null && session.memLog !== null ? (
					<div>
						<div>
							<Appprofile appProfile={cpuAppProfile?.data} timestamp={cpuAppProfile?.timeStamp} profilingType="CPU" bgColor="rgb(255, 99, 132)" borderColor="rgba(255, 99, 132, 0.2)" />
						</div>
						<div>
							<Appprofile
								appProfile={memAppProfile?.data}
								timestamp={memAppProfile?.timeStamp}
								profilingType="MEMORY"
								bgColor="rgb(54, 162, 235)"
								borderColor="rgba(54, 162, 235, 0.2)"
							/>
						</div>
					</div>
				) : (
					<NoDataFound msg="Please wait for the app profiling to be available" />
				);
			}
		}
	];

	const sessionDetailsTabs = [
		{
			name: "App",
			content: () => {
				return <SessionDetails data={session.appDetails} />;
			}
		},
		{
			name: "Input Capabilities",
			content: () => {
				return <SessionDetails data={session.capabilities} />;
			}
		}
	];

	// return !sessionFound ? (
	// 	<ErrorPage />
	// ) :

	return (
		<>
			<DeleteModal isDelete={isDelete} setDelete={setDelete} deleteMethod={sessionController.deleteSession} name={session.name} id={session.id} type={"session"} prevId={session?.build?.id} />
			<PageMeta title="Session Dashboard" description="Session details" />
			<div className="bg-background px-6 py-6 mt-8">
				{isShared === false ? (
					<SubHeader
						sessionName={session.name}
						sessionStatus={session.status}
						sessionId={sessionId}
						buildName={session?.build?.name}
						buildId={session?.build?.id}
						backgroundClass={"bg-background"}
						boxShadow={false}
						rounded={false}
						setDelete={setDelete}
					/>
				) : (
					<></>
				)}
				<Card>
					<SessionInfo session={session} sessionUser={sessionUser} sessionDuration={sessionDuration} />
				</Card>
				<div className="sessionMainContainer flex flex-col md:flex-row space-x-3 py-4 relative h-screen max-h-[calc(100vh-150px)] overflow-hidden">
					<div className="w-full md:w-3/5 overflow-hidden">
						<Card>
							<div className="h-full overflow-hidden">
								<Tabs tabs={sessionLogsTabs} />
							</div>
						</Card>
					</div>
					<div className="md:flex flex-col w-full md:w-2/5 space-y-2 ">
						<div className="h-2/5">
							<SessionVideo session={session} />
						</div>
						<div className="h-3/5">
							<Card>
								<h3 className=" pt-2">Session Details</h3>
								<Tabs tabs={sessionDetailsTabs} />
							</Card>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SessionPage;

import Duration from "./duration";
import AppiumLogs from "./appium";
import Screenshot from "./screenshot";
import mapping from "./mapping.json";

import { useRef } from "react";
import { Virtuoso } from "react-virtuoso";

const getEventType = (endpoint, protocol) => {
	if (endpoint && protocol) {
		try {
			const newEndpoint = endpoint
				.replace(/(\/)*wd\/hub\//, "")
				.split("/")
				.map((item) => (/[^a-zA-Z0-9]/.test(item) ? "*" : item))
				.join("/");
			const val = mapping[newEndpoint][protocol].command;
			return val;
		} catch (error) {
			// console.log(error);
		}
	}
};

const Footer = () => {
	return (
		<div
			style={{
				padding: "2rem",
				display: "flex",
				justifyContent: "center"
			}}
		>
			....
		</div>
	);
};

const TextLogs = ({ logs, getMoreLogs, scrollRef, isShared, shareToken }) => {
	const shmap = useRef({});

	return (
		<div className="w-full h-full overflow-hidden">
			<div className="relative wrap overflow-hidden px-4 py-6 h-full">
				<div className="border-2 left-5 absolute border-opacity-20 border-gray-700 mt-2 h-full first:border-transparent" />

				<Virtuoso
					style={{ height: "560px" }}
					data={logs}
					ref={scrollRef}
					endReached={getMoreLogs}
					components={{ Footer }}
					itemContent={(index) => {
						const log = logs[index];
						const eventName = getEventType(log?.payload?.endpoint, log?.payload?.protocol) || log?.eventType;
						// const payload = (logs.payload = {});
						// const { requestBody = {}, responseBody = {}, endpoint = "" } = payload;
						return (
							<div key={index}>
								<div className="border-2 border-gray-200 bg-white z-10 rounded-full h-3 w-3 left-0 absolute mt-1"></div>
								<div className="mb-8 flex items-center w-full">
									<Duration startTime={log?.startTime} endTime={log?.endTime} />
									<div className="w-10/12 order-1">
										<h1 className="font-weight-normal text-color-primary ">{eventName || ""}</h1>
										<div className="">
											{log?.eventType === "screenshot" ? (
												<Screenshot urlMap={shmap} url={log?.payload?.responseBody?.value} sessionId={log?.sessionId} isShared={isShared} shareToken={shareToken} />
											) : (
												<AppiumLogs endpoint={log?.payload?.endpoint || ""} request={log?.payload?.requestBody || {}} response={log?.payload?.responseBody || {}} />
											)}
										</div>
									</div>
								</div>
							</div>
						);
					}}
				/>
			</div>
		</div>
	);
};

export default TextLogs;

import PropTypes from "prop-types";
import NoDataFoundSvg from "../assets/images/no-data-found.svg";

const NoDataFound = ({ msg }) => {
	return (
		<div className="flex h-full justify-center items-center flex-col space-y-6 bg-white">
			<img className="w-52" src={NoDataFoundSvg} alt={msg !== undefined ? msg : ""} />
			<p className="font-weight-normal">{msg}</p>
		</div>
	);
};

NoDataFound.propTypes = {
	msg: PropTypes.string
};

NoDataFound.defaultProps = {
	msg: ""
};

export default NoDataFound;

import { useHistory } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import UpArrowSvg from "../../../assets/images/up-arrow.svg";
import DownArrowSvg from "../../../assets/images/down-arrow.svg";

const Table = ({ columns, data }) => {
	let history = useHistory();
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data
		},
		useSortBy
	);
	return (
		<>
			<table className="w-full table-fixed" {...getTableProps()}>
				<thead className="border-b-2">
					{headerGroups.map((headerGroup) => (
						<tr className="text-xs text-color-primary" {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									{...column.getHeaderProps({
										style: { width: column.cellWidth },
										className: `${column.className} sticky top-0 bg-foreground text-left px-6 font-weight-normal py-6 `
									})}
								>
									{column.render("Header")}
									<span>
										{column.canSort ? (
											column.isSortedDesc ? (
												<img src={DownArrowSvg} className="inline pl-1 h-5" alt="down-arrow" />
											) : (
												<img src={UpArrowSvg} className="inline pl-1 h-5" alt="up-arrow" />
											)
										) : (
											""
										)}
									</span>
								</th>
							))}
						</tr>
					))}
				</thead>
			</table>
			<div className="overflow-auto h-screen max-h-650px">
				<table className="w-full table-fixed" {...getTableProps()}>
					<tbody className="text-gray-600 text-sm font-light " {...getTableBodyProps()}>
						{rows.map((row, i) => {
							prepareRow(row);

							return (
								<tr className="cursor-pointer border-opacity-60 border-b hover:bg-gray-50" {...row.getRowProps()}>
									{row.cells.map((cell, key) => {
										return (
											<td
												{...cell.getCellProps([
													{
														className: `${cell.column.className} py-12 px-4 text-left text-color-primary font-weight-light text-sm break-words`,
														style: { width: cell.column.cellWidth }
													}
												])}
												onClick={(e) => {
													key === row.cells.length - 1 ? e.preventDefault() : history.push("/build/" + row.original.id);
												}}
											>
												{cell.render("Cell")}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default Table;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import DownloadSvg from "../../../assets/images/download.svg";
import LogsSvg from "../../../assets/images/logs.svg";
import Prism from "prismjs";

const Logs = ({ sessionId, logURL, logType }) => {
	const [logs, setLogs] = useState("");
	const [truncated, setTruncated] = useState(false);

	useEffect(() => {
		fetchLogs(logType); // Fetch logs on component mount and when logType changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [logType]);

	useEffect(() => {
		if (logs !== "") {
			Prism.highlightAll();
		}
	}, [logs]);

	const fetchLogs = async (type) => {
		try {
			const response = await axios.get(logURL);
			const logData = response.data;
			const lines = logData.split("\n");
			if (lines.length > 100) {
				const truncatedLogs = lines.slice(0, 100).join("\n");
				setLogs(truncatedLogs);
				setTruncated(true);
			} else {
				setLogs(logData);
				setTruncated(false);
			}
		} catch (error) {
			console.error("Error fetching logs:", error);
		}
	};

	const handleViewRawLogs = async () => {
		try {
			window.open(logURL, "_blank");
		} catch (error) {
			console.error("Error viewing raw logs:", error);
		}
	};

	const handleDownloadLogs = async () => {
		try {
			const response = await axios.get(logURL);
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${sessionId}-${logType}-logs.txt`);
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			console.error("Error downloading logs:", error);
		}
	};

	return (
		<div className="logs-container relative">
			<div className="sticky top-0 bg-white z-50 shadow-md">
				<div className="px-2 py-2">
					<div className="flex justify-end space-x-2 mb-2">
						<button onClick={handleDownloadLogs} className="flex items-center space-x-1 bg-primary-accent-color shadow-md rounded-md px-4 py-2 text-white text-xs font-weight-normal">
							<img src={DownloadSvg} alt="Download" className="h-3" />
							<span>Download</span>
						</button>
						<button onClick={handleViewRawLogs} className="flex items-center space-x-1 bg-blue-100 border-2 rounded-md px-4 py-2 text-xs font-weight-normal text-primary-accent-color">
							<img src={LogsSvg} alt="Logs" className="h-3" />
							<span>View Raw Logs</span>
						</button>
					</div>
				</div>
			</div>
			<div className="logs-content px-4 pt-2 overflow-y-auto">
				{logs && (
					<div className="pb-12">
						<pre className="whitespace-pre-line bg-[#F9F9F9] p-4 rounded-md break-words">
							<code className="language-javascript">{logs}</code>
						</pre>
						{truncated && (
							<p className="text-base text-black mt-2">
								<span className="text-red-500 font-size-10">Truncated logs</span> - <span className="text-black">Too long to view completely</span>.{" "}
								<span className="">Click on "View Raw Logs"</span> <span className="text-black">button above for full content</span>.
							</p>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

Logs.propTypes = {
	sessionId: PropTypes.string.isRequired,
	logURL: PropTypes.string.isRequired,
	logType: PropTypes.oneOf(["appium", "device"]).isRequired
};

export default Logs;

import React from "react";
import PropTypes from "prop-types";

export const Card = ({ children }) => {
	return <div className="bg-foreground shadow-primary rounded-md px-4 h-full">{children}</div>;
};

Card.propTypes = {
	children: PropTypes.any.isRequired
};

Card.defaultProps = {
	children: "<h3>Something went wrong.</h3>"
};

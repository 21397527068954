import { useState, useEffect } from "react";
import useDebouncedCallback from "../../../../utils/use-debounced-callback";
const AMS = ({ url }) => {
	const [screenHeight, setScreenHeight] = useState(window.innerHeight);
	const handleResize = useDebouncedCallback(() => {
		setScreenHeight(window.innerHeight);
	}, 1000);

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return (_) => {
			window.removeEventListener("resize", handleResize);
		};
		// eslint-disable-next-line
	}, []);
	const resizeVideoPlayer = () => {
		let parentHeight = document.querySelector(".sessionMainContainer").offsetHeight;
		let calculatedHeight = parentHeight * (2 / 5) - 12;
		document.querySelector(".ams-player").style.height = `${calculatedHeight}px`;
		document.querySelector(".ams-player").style.width = "100%";
	};

	useEffect(() => {
		resizeVideoPlayer();
	}, [screenHeight]);

	return (
		<div>
			<iframe width="621" className="ams-player" title="ams-player" height="1344" src={url} frameborder="0" allowfullscreen="true"></iframe>
		</div>
	);
};

export { AMS };

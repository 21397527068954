import Cookies from "universal-cookie";
const cookies = new Cookies();
const AUTH_COOKIE_NAME = "PYPCLOUDY";

export async function fetchStream(url, isS3Source = true) {
	return await fetch(url, {
		headers: {
			...(isS3Source !== true && { Authorization: `Session ${cookies.get(AUTH_COOKIE_NAME)}` })
		}
	});
}

import React, { useState, useContext } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Cookies from "universal-cookie";
import { decodeToken, isExpired } from "react-jwt";
// components
import Header from "./components/header/header.js";
import SharedSessionHeader from "./components/header/shared-session-header";
// import FullPageLoader from "../src/components/full-page-loader";
import ScrollToTop from "./components/scroll-to-top.js";

// pages
import PageNotFound from "./pages/notFound/index.js";
import SessionPage from "./pages/session";
import BuildPage from "./pages/build/index.js";
import ProjectPage from "./pages/project/index.js";

// contexts
import { LoginContext } from "./contexts/login-context";
import { LoaderContext } from "./contexts/loader-context";

const App = () => {
	//Authentication check
	let { LoginDetails } = useContext(LoginContext);
	// changed to true to make it work for now
	const [isLoading, setLoading] = useState(true);
	//Shared check
	let isShared = false;
	const paths = window.location.pathname.split("/");
	if (paths[1] === "shared" && (paths[2] === "session" || paths[2] === "build")) {
		isShared = true;
	}
	const cookies = new Cookies();
	let jwt = cookies.get("PRPCLOUDY");
	if (jwt !== undefined && !isExpired(jwt)) {
		let jwt_details = decodeToken(jwt);
		jwt_details.status = true;
		LoginDetails = jwt_details;
	} else {
		let pSessionId = cookies.get("PYPCLOUDY");
		LoginDetails.status = pSessionId !== undefined ? true : false;
	}

	return (
		<LoaderContext.Provider value={{ isLoading, setLoading }}>
			<BrowserRouter>
				<div className="theme-default bg-background h-full select-none">
					{isShared === true ? (
						<>
							<SharedSessionHeader />
							<main className="w-full container mx-auto">
								<Switch>
									<Route exact path="/shared/session/:id" component={(props) => <SessionPage {...props} isShared={true} />} />
									<Route exact path="/shared/build/:id" component={(props) => <BuildPage {...props} isShared={true} />} />
									<Route path="*" component={PageNotFound} />
								</Switch>
							</main>
						</>
					) : (
						<>
							<ScrollToTop />
							{LoginDetails.status === true ? (
								<>
									<Header UserDetails={LoginDetails} />
									<main className="w-full container mx-auto">
										<Switch>
											<Route exact path="/">
												<Redirect to="/project" />
											</Route>
											<Route exact path="/project" component={ProjectPage} />
											<Route exact path="/session/:id" component={SessionPage} />
											<Route exact path="/build/:id" component={BuildPage} />
											<Route path="*" component={PageNotFound} />
										</Switch>
									</main>
								</>
							) : process.env.NODE_ENV === "production" ? (
								<Route
									path="/"
									component={() => {
										window.location.href = process.env.REACT_APP_HOME_URL ? process.env.REACT_APP_HOME_URL : "https://pcloudy.com/";
										return null;
									}}
								/>
							) : (
								<PageNotFound />
							)}
							<Toaster position="bottom-right" />
						</>
					)}
				</div>
			</BrowserRouter>
		</LoaderContext.Provider>
	);
};

export default App;

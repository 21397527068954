import axios from "axios";
import prClient from "../../../common/prClient";
import { sseSubscribe } from "../../../common/sse";

const sessionController = {
	async getSessionById(sessionId, isShared, shareToken = "") {
		const url = isShared ? `/shared/session/${sessionId}/${shareToken}` : "/session/" + sessionId;
		try {
			const resp = await prClient({
				method: "get",
				url
			});
			return resp;
		} catch (error) {
			console.log("Get Session Error: ", error);
			throw error;
		}
	},
	getTextLogs: (sessionId, isShared, shareToken = "", lastEventOffset) => {
		const url = isShared ? `/shared/session/${sessionId}/${shareToken}/events` : `/session/${sessionId}/events`;
		let data = {
			...(lastEventOffset && { createdAt: lastEventOffset })
		};
		return prClient({
			method: "post",
			url,
			data: data
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				return error;
			});
	},
	getAppProfileData: (appProfileUrl) => {
		if (appProfileUrl)
			return axios.get(appProfileUrl).then(
				(response) => {
					const timeArr = [];
					const graphDataArr = [];
					try {
						const appProfileDataResPonse = response.data.replace(/\r\n/g, "\r").replace(/\n/g, "\r").split(/\r/);
						appProfileDataResPonse.forEach((product) => {
							timeArr.push(product.split("->")[0]);
							graphDataArr.push(product.split("->")[1]);
						});
						return { timeStamp: timeArr, data: graphDataArr };
					} catch (err) {
						console.log("caught error for : ", err);
						throw err;
					}
				},
				(error) => {
					console.log("ERROR RESPONSE : ", error);
					throw error;
				}
			);
	},
	subscribeSession: (sessionId) => {
		const event_url = `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
		return sseSubscribe(`${event_url}/subscribe/session/${sessionId}`);
	},
	subscribeToSessionEvents: (sessionId, cloudUrl) => {
		const baseUrl = cloudUrl;

		/* always start path with leading forward slash `/` */
		const event_url = new URL(`/logcollector/api/v1/subscribe/session/${sessionId}/events`, baseUrl).href;
		return sseSubscribe(event_url);
	},
	getSessionDuration: (sessionId, isShared, shareToken = "") => {
		const url = isShared ? `/shared/session/${sessionId}/${shareToken}/sessionDuration` : `/session/${sessionId}/sessionDuration`;
		return prClient({
			method: "get",
			url
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				console.log("Get Session Duration Error: ", error);
				return error;
			});
	},
	getUsername: (sessionId, isShared, shareToken = "") => {
		const url = isShared ? `/shared/session/${sessionId}/${shareToken}/user` : `/session/${sessionId}/user`;
		return prClient({
			method: "post",
			url
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				console.log("Get Session User Error: ", error);
				return error;
			});
	},
	deleteSession: (sessionId) => {
		return prClient({
			method: "delete",
			url: `/session/${sessionId}`
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				console.log("Delete Session Error: ", error);
				return error;
			});
	},
	updateSessionDetails: (sessionId, updatedData) => {
		const { name, status } = updatedData;
		const requestBody = {
			name,
			status
		};

		return prClient({
			method: "put",
			url: `/session/${sessionId}`,
			data: requestBody
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				console.log("Update Session Error: ", error);
				throw error;
			});
	},

	getSharableLink: (sessionId) => {
		return prClient({
			method: "get",
			url: `/session/${sessionId}/share`
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				console.log("Shareable Link Error: ", error);
				throw error;
			});
	},
	async getScreenshotUrl(s3key, sessionId, isShared, shareToken) {
		try {
			const resp = await prClient({
				method: "post",
				url: isShared === true ? `/shared/event/get-screenshot-url/${sessionId}/${shareToken}` : `/event/get-screenshot-url`,
				data: {
					s3key
				}
			});
			return resp.data;
		} catch (error) {
			throw error;
		}
	}
};

export default sessionController;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Card } from "../../../components/card";
import { LoaderContext } from "../../../contexts/loader-context";
import ProjectSearch from "./project-search";
import ProjectFilter from "./project-filter";
import projectController from "../controller/project-controller";
import DateFilter from "./date-filter";
import { countBy, keys } from "lodash";

const SubHeader = ({ allBuilds, setAllBuilds, setLoadingBuildDetailsTable }) => {
	let queryParam = useRef("");
	const { setLoading } = useContext(LoaderContext);
	const [serverEvent, setServerEvent] = useState(null);
	const [selectedFromDate, setFromDate] = useState(null);
	const [selectedToDate, setToDate] = useState(null);

	useEffect(() => {
		(async function () {
			if (!selectedFromDate || !selectedToDate) return;

			const fromDate = selectedFromDate.toString().slice(0, 10);
			const toDate = selectedToDate.toString().slice(0, 10);

			queryParam.current = `from=${fromDate}&to=${toDate}`;

			setLoadingBuildDetailsTable(true);
			await getAndSetBuilds();
			setLoadingBuildDetailsTable(false);
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFromDate, selectedToDate]);

	useEffect(() => {
		(async function () {
			await getAndSetBuilds();
			setLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (serverEvent !== null) {
			getAndSetBuilds();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serverEvent]);

	useEffect(() => {
		let events = "";
		if (allBuilds && allBuilds.length > 0) {
			let projectIds = keys(countBy(allBuilds, (build) => build.projectId));
			projectIds = projectIds.toString();
			events = projectController.subscribeProject(projectIds);
			events.onmessage = (event) => {
				if (event.data !== "keep-alive") setServerEvent(event);
			};
		}
		return () => {
			if (events) {
				events.close();
			}
		};
	}, [allBuilds]);

	const getAndSetBuilds = async () => {
		let resp = await projectController.getAllBuilds(queryParam.current);
		if (resp.status === 200) {
			setAllBuilds(resp.data);
		}
	};

	return (
		<div className="my-4">
			<Card>
				<div className="flex justify-between py-2">
					<div className="py-4 flex space-x-2 relative">
						<ProjectFilter allBuilds={allBuilds} setAllBuilds={setAllBuilds} setLoadingBuildDetailsTable={setLoadingBuildDetailsTable} />
					</div>
					<div className="flex space-x-6">
						<DateFilter
							selectedFromDate={selectedFromDate}
							setFromDate={setFromDate}
							selectedToDate={selectedToDate}
							setToDate={setToDate}
							getAndSetBuilds={getAndSetBuilds}
							setLoadingBuildDetailsTable={setLoadingBuildDetailsTable}
							queryParam={queryParam}
						/>
						<ProjectSearch />
					</div>
				</div>
			</Card>
		</div>
	);
};

export default SubHeader;

import { useState } from "react";
import UserSvg from "../../assets/images/user-icon.svg";
import DownArrowSvg from "../../assets/images/down-arrow.svg";
import pCloudyLogo from "../../assets/images/pCloudy-logo-white.png";

const Header = ({ UserDetails }) => {
	const [isDropdownVisible, setDropdownVisible] = useState(false);
	return (
		<>
			<nav className="fixed bg-side-panel w-full top-0 z-30 ">
				<div className="flex flex-row justify-between items-center h-full relative right-0 px-3 w-full container mx-auto">
					<img className="h-10 transform hover:-translate-y-px cursor-pointer" src={pCloudyLogo} alt="pcloudy-logo" />
					<div
						className="relative inline-block text-left"
						onBlur={(e) => {
							e.preventDefault();
							setDropdownVisible(!isDropdownVisible);
						}}
					>
						<div>
							<button
								type="button"
								className="flex items-center space-x-2 w-full rounded-md border  shadow-sm py-2 text-sm font-medium text-white border-none"
								onClick={(e) => {
									e.preventDefault();
									setDropdownVisible(!isDropdownVisible);
								}}
							>
								<img src={UserSvg} alt="user icon" className="h-5" />
								<span>{UserDetails.name ? UserDetails.name : "-"}</span>
								<img src={DownArrowSvg} alt="user icon" className="h-5" />
							</button>
						</div>
						<div
							className={`${
								isDropdownVisible === true ? "" : "hidden"
							} origin-top-right absolute right-0 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
						>
							{/* TODO
            make this list dynamic */}
							<div className="py-1">
								<a href="http://google.com" className="text-gray-700 block px-4 py-2 text-sm">
									Logout
								</a>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Header;

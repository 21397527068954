import prClient from "../../../common/prClient";
import { sseSubscribe } from "../../../common/sse";

const buildController = {
	getBuildById: (buildId, isShared, shareToken = "") => {
		const url = isShared ? `/shared/build/${buildId}/${shareToken}` : `/build/${buildId}`;
		return prClient({
			method: "get",
			url
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				console.log("Get Build Error: ", error);
				return error;
			});
	},
	getBuildSessionsById: (buildId, isShared, shareToken = "", queryParams) => {
		const url = isShared ? `/shared/build/${buildId}/${shareToken}/sessions` : `/build/${buildId}/sessions`;
		return prClient({
			method: "get",
			url: `${url}${queryParams === undefined ? "" : "?" + queryParams}`
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				console.log("Get Build Sessions Error: ", error);
				return error;
			});
	},
	getTotalSessionsById: (buildId, isShared, shareToken = "") => {
		const url = isShared ? `/shared/build/${buildId}/${shareToken}/totalSessions` : `/build/${buildId}/totalSessions`;
		return prClient({
			method: "get",
			url
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				return error;
			});
	},
	getStatusCount: (buildId, isShared, shareToken = "") => {
		const url = isShared ? `/shared/build/${buildId}/${shareToken}/statusCount` : `/build/${buildId}/statusCount`;
		return prClient({
			method: "get",
			url
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				return error;
			});
	},
	subscribeBuild: (buildId) => {
		const event_url = `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
		return sseSubscribe(`${event_url}/subscribe/build/${buildId}`);
	},
	getBuildDuration: (buildId, isShared, shareToken = "") => {
		const url = isShared ? `/shared/build/${buildId}/${shareToken}/getBuildDuration` : `/build/${buildId}/getBuildDuration`;
		return prClient({
			method: "get",
			url
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				return error;
			});
	},
	getBuildUsername: async (buildId, isShared, shareToken = "") => {
		const url = isShared ? `/shared/build/${buildId}/${shareToken}/user` : `/build/${buildId}/user`;
		return prClient({
			method: "post",
			url
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				console.log("Get Build User Error: ", error);
				return error;
			});
	},
	deleteBuild: (buildId) => {
		return prClient({
			method: "delete",
			url: `/build/${buildId}`
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				console.log("Delete Build Error: ", error);
				return error;
			});
	},
	getSharableLink: (buildId) => {
		return prClient({
			method: "get",
			url: `/build/${buildId}/share`
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				console.log("Shareable Link Error: ", error);
				throw error;
			});
	},
	mergeBuilds: (buildIds) => {
		return prClient({
			method: "post",
			url: `/analytics/build/merge`,
			data: buildIds
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				console.log("Merge Builds Error: ", error);
				throw error;
			});
	},
	compareBuilds: (buildIds) => {
		return prClient({
			method: "post",
			url: `/analytics/build/compare`,
			data: buildIds
		})
			.then((resp) => {
				return resp;
			})
			.catch((error) => {
				console.log("Compare Builds Error: ", error);
				throw error;
			});
	}
};

export default buildController;

import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

const TestResultChart = ({ pieChartData }) => {
	const [chartHeight, setchartHeight] = useState("auto");
	const [chartWidth, setChartWidth] = useState("auto");

	//setting up colors array according to piechart data
	const statusColors = {
		Fail: "#E85252",
		Pass: "#00BD85",
		"In Progress": "#E0A455",
		Completed: "#1C7DF4",
		Timedout: "#c634eb"
	};

	const colors = [];

	for (let i = 1; i < pieChartData.length; i++) {
		const status = pieChartData[i][0];
		const color = statusColors[status] ? statusColors[status] : "pink";
		colors.push(color);
	}

	const makeChartResponsive = () => {
		let parentHeight = document.querySelector(".buidInfoAndPieChartContainer").offsetHeight;
		let parentWidth = document.querySelector(".buidInfoAndPieChartContainer").offsetWidth;
		let calculatedHeight = parentHeight + 48;
		let calculatedWidth = parentWidth * (1 / 3) - 18;

		setchartHeight(`${calculatedHeight}px`);
		setChartWidth(`${calculatedWidth}px`);
	};

	useEffect(() => {
		makeChartResponsive();
		// eslint-disable-next-line
	}, []);

	return (
		<Chart
			width={chartWidth}
			height={chartHeight}
			chartType="PieChart"
			loader={<div>Loading Chart</div>}
			data={pieChartData}
			options={{
				title: "Test Case Result",
				chartArea: {},
				fontSize: 12,
				colors: colors
			}}
			rootProps={{ "data-testid": "2" }}
		/>
	);
};

export default TestResultChart;

import { useEffect, useRef, useState } from "react";
import CloseIconRed from "../../assets/images/close-blue.svg";

const SelectedFilterCards = ({ activeFilters, setActiveFilters, FILTERS_TEXT, handleFilterChange }) => {
	const [filterRemoved, setFilterRemoved] = useState(0);
	const isInitialMount = useRef(true);
	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
			return;
		}
		handleFilterChange();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterRemoved]);

	return (
		<>
			<div className="flex align-baseline items-end flex-wrap space-y-2">
				{Object.keys(activeFilters).map((key, index) => {
					if (Object.keys(activeFilters[key]).length === 0) return "";
					return (
						<div key={index} className="flex items-center space-x-2 mx-2 bg-blue-100 border-2 rounded-md px-2 py-1 text-xs font-weight-normal">
							<span>{FILTERS_TEXT[key]}: </span>

							<span className="text-color-secondary font-weight-light">{Object.values(activeFilters[key]).join(", ")}</span>
							<img
								src={CloseIconRed}
								className="h-5 block cursor-pointer"
								alt="filter"
								onClick={() => {
									let currentSelectedFilters = Object.assign({}, activeFilters);
									currentSelectedFilters[key] = {};
									setActiveFilters((prevState) => currentSelectedFilters);
									setFilterRemoved((prevState) => !prevState);
								}}
							/>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default SelectedFilterCards;

import React from "react";
import { Chart } from "react-google-charts";

const MergeModal = ({ isOpen, onRequestClose, data }) => {
	if (!isOpen) return null;

	const buildNames = data.buildInfoResult.map((build) => build.name);

	const legendNames = {
		completed: "Completed",
		fail: "Failed",
		"in-progress": "In Progress",
		pass: "Passed",
		timedout: "Timedout"
	};

	const statusColors = {
		completed: "#1C7DF4",
		fail: "#E85252",
		"in-progress": "#E0A455",
		pass: "#00BD85",
		timedout: "#c634eb"
	};

	const statusCountsMap = {};
	const { statusCountResult } = data;
	for (let i = 0; i < statusCountResult.length; i++) {
		const status = statusCountResult[i];
		const lowerStatus = status.status.toLowerCase();
		if (!legendNames[lowerStatus]) {
			continue;
		}
		if (!statusCountsMap[lowerStatus]) {
			statusCountsMap[lowerStatus] = 0;
		}
		statusCountsMap[lowerStatus] += status.statuscount;
	}

	const statusCounts = Object.keys(statusCountsMap).map((key) => [legendNames[key], statusCountsMap[key]]);
	const chartData = [["Status", "Count"], ...statusCounts];
	console.log({ chartData });
	const colors = Object.keys(statusCountsMap).map((key) => statusColors[key]);

	const options = {
		is3D: true,
		colors,
		legend: {
			textStyle: { fontSize: 14 }
		},
		chartArea: {
			left: 50,
			top: 50,
			width: "80%",
			height: "80%"
		}
	};

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
			<div className="relative bg-white p-5 rounded-lg max-w-lg w-full shadow-lg">
				<h2 className="text-black mb-8 mt-2 text-xl font-bold text-center">Merged Builds</h2>
				<button className="absolute top-6 right-6 text-2xl text-gray-600 hover:text-gray-800" onClick={onRequestClose}>
					&times;
				</button>
				<h3 className="text-blue-500 pb-2">Builds</h3>
				<ul className="text-sm text-gray-600">
					{buildNames.map((name) => (
						<li className="list-disc ml-4" key={name}>
							{name}
						</li>
					))}
				</ul>
				<div className="chart-container">
					<Chart chartType="PieChart" width="100%" height="300px" data={chartData} options={options} />
				</div>
			</div>
		</div>
	);
};

export default MergeModal;

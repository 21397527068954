import { Box } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateInput from "../../../components/date";
import useDebouncedCallback from "../../../utils/use-debounced-callback";
import { useEffect, useState } from "react";

const DateFilter = ({ selectedFromDate, setFromDate, selectedToDate, setToDate, getAndSetBuilds, setLoadingBuildDetailsTable, queryParam }) => {
	const [showResetDateBtn, setShowResetDateBtn] = useState(false);

	const handleFromDate = useDebouncedCallback((value) => {
		setFromDate(value);
	}, 500);

	const handleToDate = useDebouncedCallback((value) => {
		setToDate(value);
	}, 100);

	const resetDateFilters = async () => {
		setFromDate(null);
		setToDate(null);
		queryParam.current = "";
		setLoadingBuildDetailsTable(true);
		await getAndSetBuilds();
		setLoadingBuildDetailsTable(false);
	};

	useEffect(() => {
		if (selectedFromDate === null && selectedToDate === null) {
			setShowResetDateBtn(false);
		}

		if (selectedFromDate !== null && selectedToDate !== null) {
			setShowResetDateBtn(true);
		}
	}, [selectedFromDate, selectedToDate]);

	return (
		<>
			<div className="relative w-44 self-center flex justify-center space-x-2">
				<span className="self-center text-sm text-color-primary font-weight-light">From</span>
				<LocalizationProvider dateAdapter={AdapterLuxon}>
					<DatePicker
						value={selectedFromDate}
						inputFormat="YYYY-MM-DD"
						onChange={(value) => handleFromDate(value)}
						renderInput={({ inputRef, inputProps, InputProps }) => (
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<DateInput inputRef={inputRef} InputProps={InputProps} placeHolder={"Select Date"} {...inputProps} />
							</Box>
						)}
					/>
				</LocalizationProvider>
			</div>
			<div className="relative w-40 self-center flex justify-center space-x-2 ">
				<span className="self-center text-sm text-color-primary font-weight-light">To</span>

				<LocalizationProvider dateAdapter={AdapterLuxon}>
					<DatePicker
						value={selectedToDate}
						inputFormat="YYYY-MM-DD"
						onChange={(value) => handleToDate(value)}
						renderInput={({ inputRef, inputProps, InputProps }) => (
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<DateInput inputRef={inputRef} InputProps={InputProps} placeHolder={"Select Date"} {...inputProps} />
							</Box>
						)}
					/>
				</LocalizationProvider>
			</div>
			{!showResetDateBtn ? (
				""
			) : (
				<button className="self-center text-sm text-yellow-600 cursor-pointer pr-2" onClick={() => resetDateFilters()}>
					Reset
				</button>
			)}
		</>
	);
};

export default DateFilter;

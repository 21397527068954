import { startCase, camelCase } from "lodash";

const localTime = (date = new Date()) => {
	return `${new Date(Date.parse(date)).toLocaleDateString()} ${new Date(Date.parse(date)).toLocaleTimeString()}`;
};

const titleCase = (str) => {
	return startCase(camelCase(str));
};

const relativeTime = (d1, d2 = new Date()) => {
	var units = {
		year: 24 * 60 * 60 * 1000 * 365,
		month: (24 * 60 * 60 * 1000 * 365) / 12,
		day: 24 * 60 * 60 * 1000,
		hour: 60 * 60 * 1000,
		minute: 60 * 1000,
		second: 1000
	};

	var rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

	d1 = new Date(d1);
	if (!(d1 instanceof Date && isFinite(d1))) return "-";

	var elapsed = d1 - d2;

	// "Math.abs" accounts for both "past" & "future" scenarios
	for (var u in units) if (Math.abs(elapsed) > units[u] || u === "second") return rtf.format(Math.round(elapsed / units[u]), u);
};

const secondsToTimeInUnits = (seconds) => {
	//Calculate Difference into Days,Hours,Minutes and Seconds
	const daysDifference = Math.floor(seconds / 60 / 60 / 24);
	seconds -= daysDifference * 60 * 60 * 24;

	const hoursDifference = Math.floor(seconds / 60 / 60);
	seconds -= hoursDifference * 60 * 60;

	const minutesDifference = Math.floor(seconds / 60);
	seconds -= minutesDifference * 60;

	const secondsDifference = Math.floor(seconds);

	//Formate the difference
	const formatedDaysDifference = daysDifference <= 0 || isNaN(daysDifference) ? "" : daysDifference < 10 ? "0" + daysDifference + "d" : daysDifference + "d";
	const formatedHoursDifference = hoursDifference <= 0 || isNaN(hoursDifference) ? "" : hoursDifference < 10 ? "0" + hoursDifference + "h" : hoursDifference + "h";
	const formatedMinutesDifference = minutesDifference <= 0 || isNaN(minutesDifference) ? "" : minutesDifference < 10 ? "0" + minutesDifference + "m" : minutesDifference + "m";
	const formatedSecondsDifference = secondsDifference <= 0 || isNaN(secondsDifference) ? "" : secondsDifference < 10 ? "0" + secondsDifference + "s" : secondsDifference + "s";

	const duration = formatedDaysDifference + " " + formatedHoursDifference + " " + formatedMinutesDifference + " " + formatedSecondsDifference;

	return duration;
};

const timestampToDateTime = (timestamp) => {
	const date = new Date(timestamp * 1000);
	return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
};

export { localTime, titleCase, relativeTime, secondsToTimeInUnits, timestampToDateTime };

import React, { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import prClient from "../../../common/prClient";

// components
import Search from "../../../components/search";
import SearchSuggestions from "./search-suggestions";

export const SharedSubHeader = ({ backgroundClass, boxShadow, rounded, isShared, shareToken }) => {
	const { id } = useParams();
	const [showSugesstions, setShowSugesstions] = useState(false);
	const searchKey = useRef("");
	// eslint-disable-next-line
	const delayedSearch = useCallback(
		debounce((key) => getSuggestions(key), 500),
		[]
	);

	const handleSearch = (key) => {
		searchKey.current = key;
		if (key.length > 2) {
			return delayedSearch(key);
		} else {
			// hide suggestions component
			setShowSugesstions(false);
		}
	};

	const [suggestionsData, setSuggestionsData] = useState([]);

	const getSuggestions = (key) => {
		prClient({
			method: "get",
			url: `/shared/build/${id}/${shareToken}/sessions/${key}`
		}).then(
			(resp) => {
				if (searchKey.current.length > 2) {
					setShowSugesstions(true);
					setSuggestionsData(resp?.data[0]?.sessions || []);
				}
			},
			(reason) => {
				console.log(reason);
				//toast showing something went wrong
			}
		);
	};

	return (
		<>
			<div className={backgroundClass + (boxShadow === true ? "shadow-primary" : "") + (rounded === true ? " rounded-md" : "")}>
				<div className="space-y-4 mx-auto flex flex-wrap py-6 flex-col md:flex-row-reverse items-center justify-between">
					<div className="w-52 relative">
						<Search handleSearch={handleSearch} />
						{showSugesstions ? <SearchSuggestions suggestionsData={suggestionsData} isShared={isShared} shareToken={shareToken} /> : ""}
					</div>
				</div>
			</div>
		</>
	);
};

SharedSubHeader.propTypes = {
	sessionName: PropTypes.string,
	backgroundClass: PropTypes.oneOf(["bg-foreground", "bg-background"]),
	boxShadow: PropTypes.bool,
	rounded: PropTypes.bool
};

SharedSubHeader.defaultProps = {
	sessionName: null,
	backgroundClass: "bg-background",
	boxShadow: false,
	rounded: false
};

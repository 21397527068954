const Duration = ({ startTime, endTime }) => {
	const getDiffInMillisecond = (start, end) => {
		let d1 = new Date(start);
		let d2 = new Date(end);
		let diff = Math.abs(d2 - d1);
		return diff;
	};

	const getDurationInHMS = (ms) => {
		let minutes = Math.floor(ms / 60000);
		let seconds = ((ms % 60000) / 1000).toFixed(0);
		return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
	};

	const getDuration = (start, end) => {
		let diff = getDiffInMillisecond(start, end);
		return getDurationInHMS(diff);
	};

	return (
		<div className="mx-4  px-2 self-start bg-gray-200 rounded-sm">
			<h1 className="">{getDuration(startTime, endTime)}</h1>
		</div>
	);
};

export default Duration;

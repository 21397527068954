import { useEffect, useState } from "react";
import prClient from "../../../common/prClient";
import FilterButton from "../../../components/filter/filter-button";
import ResetFilters from "../../../components/filter/reset-filters";
import SelectedFilterCards from "../../../components/filter/selected-filter-cards";
import BuildDetailsFilterPopup from "../../../components/filter/filter-popup";
import { OS, OS_TEXT, PAGINATED_RECORDS_PER_PAGE, SESSION_FILTER, SESSION_FILTERS_TEXT, SESSION_STATUS, SESSION_STATUS_TEXT } from "../../../constants";
import buildController from "../controllers/build-controller";
import { isEqual } from "lodash";

const BuildFilter = ({ setSessionsResp, setLoadingSessionDetailsTable, buildId, activeFilters, setActiveFilters, getQueryParamsOfActiveFilters, activeFiltersInitialState, isShared, shareToken }) => {
	const [isFilterOpen, setFilterOpen] = useState(false);
	const [tagFiltersList, setTagFiltersList] = useState([]);
	const [deviceFiltersList, setdeviceFiltersList] = useState([]);

	useEffect(() => {
		getTagsByBuild();
		getDevicessByBuild();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handleFilterChange();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeFilters]);

	const getTagsByBuild = () => {
		const url = isShared ? `/shared/build/${buildId}/${shareToken}/filter/tag` : `/build/${buildId}/filter/tag`;
		prClient({
			method: "get",
			url
		}).then(
			(resp) => {
				// this should be done by backend
				let uniqTags = new Set(resp.data.tag);
				setTagFiltersList(Array.from(uniqTags));
			},
			(reason) => {
				console.log(reason);
			}
		);
	};

	const getDevicessByBuild = () => {
		const url = isShared ? `/shared/build/${buildId}/${shareToken}/filter/device` : `/build/${buildId}/filter/device`;
		prClient({
			method: "get",
			url
		}).then(
			(resp) => {
				// this should be done by backend
				let uniqDevices = new Set(resp.data.device);
				setdeviceFiltersList(Array.from(uniqDevices));
			},
			(reason) => {
				console.log(reason);
			}
		);
	};

	const handleFilterChange = () => {
		let paginationQueryParam = `skip=0&take=${PAGINATED_RECORDS_PER_PAGE}`;
		let queryParams = getQueryParamsOfActiveFilters() === "" ? `${paginationQueryParam}` : `${getQueryParamsOfActiveFilters()}&${paginationQueryParam}`;

		setLoadingSessionDetailsTable(true);
		buildController.getBuildSessionsById(buildId, isShared, shareToken, queryParams).then((resp) => {
			if (resp.status === 200) {
				setSessionsResp(resp?.data);
				setLoadingSessionDetailsTable(false);
			} else {
				setLoadingSessionDetailsTable(false);
			}
		});
		//Activefilters--->QueryParams
		const url = new URL(window.location);
		if (isEqual(activeFilters, activeFiltersInitialState)) {
			//remove filters from url if no filters are applied
			const params = Object.fromEntries(new URLSearchParams(url.search));
			if (params.hasOwnProperty("filters")) {
				url.searchParams.delete("filters");
				window.history.pushState(null, "", url.toString());
			}
		} else {
			//add filters to url search params
			url.searchParams.set("filters", JSON.stringify(activeFilters));
			window.history.pushState(null, "", url.toString());
		}
	};

	const filters = [
		{
			title: SESSION_FILTERS_TEXT[SESSION_FILTER.STATUS],
			key: SESSION_FILTER.STATUS,
			options: Object.fromEntries(
				//options will stored in key: value pair. Here, key will be used in query params and value will be used as text for UI
				Object.values(SESSION_STATUS).map((_, i) => [Object.values(SESSION_STATUS)[i], Object.values(SESSION_STATUS_TEXT)[i]])
			)
		},
		{
			title: SESSION_FILTERS_TEXT[SESSION_FILTER.DEVICES],
			key: SESSION_FILTER.DEVICES,
			options: Object.fromEntries(deviceFiltersList.map((_, i) => [deviceFiltersList[i], deviceFiltersList[i]]))
		},
		{
			title: SESSION_FILTERS_TEXT[SESSION_FILTER.TAGS],
			key: SESSION_FILTER.TAGS,
			options: Object.fromEntries(tagFiltersList.map((_, i) => [tagFiltersList[i], tagFiltersList[i]]))
		},
		{
			title: SESSION_FILTERS_TEXT[SESSION_FILTER.OS],
			key: SESSION_FILTER.OS,
			options: Object.fromEntries(Object.values(OS).map((_, i) => [Object.values(OS)[i], Object.values(OS_TEXT)[i]]))
		}
	];
	return (
		<>
			<FilterButton isFilterOpen={isFilterOpen} setFilterOpen={setFilterOpen} />

			<SelectedFilterCards activeFilters={activeFilters} setActiveFilters={setActiveFilters} FILTERS_TEXT={SESSION_FILTERS_TEXT} handleFilterChange={handleFilterChange} />
			<ResetFilters setActiveFilters={setActiveFilters} activeFiltersInitialState={activeFiltersInitialState} activeFilters={activeFilters} handleFilterChange={handleFilterChange} />

			{isFilterOpen ? (
				<div className="w-60 rounded-md shadow-primary p-4 bg-foreground absolute z-20 -left-4 top-14  ">
					<BuildDetailsFilterPopup
						setFilterOpen={setFilterOpen}
						setLoadingBuildDetailsTable={setLoadingSessionDetailsTable}
						activeFilters={activeFilters}
						setActiveFilters={setActiveFilters}
						handleFilterChange={handleFilterChange}
						filters={filters}
					/>
				</div>
			) : (
				""
			)}
		</>
	);
};

export default BuildFilter;

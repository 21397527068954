import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import useDebouncedCallback from "../../../../utils/use-debounced-callback";

const OND = ({ url }) => {
	const [screenHeight, setScreenHeight] = useState(window.innerHeight);
	const handleResize = useDebouncedCallback(() => {
		setScreenHeight(window.innerHeight);
	}, 1000);

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return (_) => {
			window.removeEventListener("resize", handleResize);
		};
		// eslint-disable-next-line
	}, []);

	const resizeVideoPlayer = () => {
		let parentHeight = document.querySelector(".sessionMainContainer").offsetHeight;
		let calculatedHeight = parentHeight * (2 / 5) - 12;
		document.querySelector(".react-player").style.height = `${calculatedHeight}px`;
		document.querySelector(".react-player").style.width = "100%";
	};

	useEffect(() => {
		resizeVideoPlayer();
	}, [screenHeight]);
	return (
		<div>
			<ReactPlayer url={url} className="react-player" controls={true} width="100%" height="38vh" onError={() => console.error(`Failed to load video. [${url}]`)} />
		</div>
	);
};

export { OND };

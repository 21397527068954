import PropTypes from "prop-types";
import LoadingAnimation from "../../../../assets/images/loadingVideo.gif";

const LoadingVideo = ({ msg }) => {
	return (
		<div className="flex h-full justify-center items-center flex-col space-y-6 bg-white">
			<img className="w-56" src={LoadingAnimation} alt={msg !== undefined ? msg : ""} />
			<p className="font-weight-normal">{msg}</p>
		</div>
	);
};

LoadingVideo.propTypes = {
	msg: PropTypes.string
};

LoadingVideo.defaultProps = {
	msg: ""
};

export default LoadingVideo;

import { isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";

const ResetFilters = ({ setActiveFilters, activeFiltersInitialState, activeFilters, handleFilterChange }) => {
	const [resetAllFilters, setResetAllFilters] = useState(0);
	const isInitialMount = useRef(true);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
			return;
		}
		handleFilterChange();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetAllFilters]);

	return isEqual(activeFilters, activeFiltersInitialState) ? (
		""
	) : (
		<button
			className="self-center text-sm text-yellow-600 cursor-pointer pr-2"
			onClick={() => {
				setActiveFilters(activeFiltersInitialState);
				setResetAllFilters((prevState) => !prevState);
			}}
		>
			Reset All
		</button>
	);
};

export default ResetFilters;

import { useHistory } from "react-router-dom";

const SearchSuggestions = ({ suggestionsData }) => {
	let history = useHistory();
	return (
		<div className="absolute text-gray-500 mt-2  w-64 right-0 rounded-md shadow-primary max-h-80 overflow-auto z-20">
			{!suggestionsData.length ? (
				<p className="bg-white border border-gray-100 w-full px-2 py-4 text-sm font-weight-normal">No result found.</p>
			) : (
				<ul className="bg-white border border-gray-100 w-full ">
					{suggestionsData.map((key, index) => {
						let projectId = suggestionsData[index].id;
						let projectName = suggestionsData[index].name;
						return (
							<li
								onClick={() => history.push("/build/" + projectId)}
								className="pl-4 pr-2 py-4 border-b-2 border-gray-100 relative cursor-pointer hover:bg-background hover:text-gray-900"
							>
								<span className="break-all block text-base font-weight-normal text-color-primary">{projectName}</span>
								<span className="break-all block text-sm text-color-tertiary">{projectId}</span>
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};

export default SearchSuggestions;

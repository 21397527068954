import { titleCase } from "../../../common/prCommon";

// images
import DurationSvg from "../../../assets/images/duration.svg";
import IdSvg from "../../../assets/images/id.svg";
import LastUpdatedSvg from "../../../assets/images/last-updated.svg";
import StatusSvg from "../../../assets/images/status.svg";
import TotalSessionsSvg from "../../../assets/images/notes.svg";
import UserSvg from "../../../assets/images/user.svg";

const BuildInfo = ({ buildLastUpdated, buildUsername, buildDetails, buildDuration, totalSessionsCount }) => {
	const buildInfoContent = [
		{
			icon: StatusSvg,
			iconAltText: "status",
			heading: "Build Status",
			content: titleCase(buildDetails.status)
		},
		{
			icon: DurationSvg,
			iconAltText: "duration",
			heading: "Duration",
			content: buildDuration
		},
		{
			icon: IdSvg,
			iconAltText: "build-id",
			heading: "Build ID",
			content: buildDetails.id
		},
		{
			icon: LastUpdatedSvg,
			iconAltText: "last-updated",
			heading: "Last Updated",
			content: buildLastUpdated
		},
		{
			icon: TotalSessionsSvg,
			iconAltText: "sessions",
			heading: "Sessions",
			content: totalSessionsCount
		},
		{
			icon: UserSvg,
			iconAltText: "User Name",
			heading: "User",
			content: buildUsername
		}
	];
	return (
		<div className="h-auto grid grid-cols-1 md:grid-cols-3 gap-6 py-12 justify-items-center md:justify-items-start space-y-2 p-4  ">
			{buildInfoContent.map((info, index) => {
				return (
					<div className="flex items-center space-x-4" key={index}>
						<img className="h-12" src={info.icon} alt={info.iconAltText} />
						<div>
							<span className="block text-color-tertiary text-xs">{info.heading}</span>
							<span className="block text-color-primary text-base font-weight-normal">{info.content}</span>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default BuildInfo;

import ModalImage from "react-modal-image";
import { useState, useEffect, memo } from "react";
import sessionController from "../../controllers/session-controller";
/* eslint-disable react-hooks/exhaustive-deps */
const Screenshot = ({ url, urlMap, sessionId, isShared, shareToken }) => {
	const [ssUrl, setSSurl] = useState("");

	async function urlToS3Url() {
		if (urlMap.current[url]) {
			setSSurl(urlMap.current[url]);
		} else if (urlMap.current[url] !== "ongoing") {
			urlMap.current[url] = "ongoing";
			const { data } = await sessionController.getScreenshotUrl(url, sessionId, isShared, shareToken).catch((err) => {
				console.log(err);
			});
			urlMap.current[url] = data?.screenShotUrl;
			setSSurl(data.screenShotUrl);
		}
	}
	useEffect(() => {
		urlToS3Url();
	}, [url]);
	return (
		<>
			<div className="order-1 rounded-lg px-4 pb-6 pt-2 w-full">
				<ModalImage small={ssUrl} large={ssUrl} className="modal-image" alt="Screenshot" hideZoom={true} hideDownload={true} />
			</div>
		</>
	);
};

export default memo(Screenshot);

import { useState } from "react";
import CloseSvg from "../../assets/images/close-red.svg";
import CheckboxSvg from "../../assets/svgs/checkbox.js";
import UpArrowSvg from "../../assets/images/up-arrow.svg";

const FilterPopup = ({ setFilterOpen, activeFilters, setActiveFilters, handleFilterChange, filters }) => {
	const [filterListOpen, setFilterListOpen] = useState(null);
	const [tempFilters, setTempFilters] = useState(activeFilters);

	const handleFilterSelect = (filterName, selectedItemKey, selectedItemValue) => {
		// this function will be called when user clicks on the checkbox
		const isSelected = tempFilters[filterName].hasOwnProperty(selectedItemKey);
		if (!isSelected) {
			// if item is not in the array add it else remove it
			setTempFilters((prev) => ({
				...prev,
				[filterName]: {
					...prev[filterName],
					[selectedItemKey]: selectedItemValue
				}
			}));
		} else {
			let currentSelectedFilters = Object.assign({}, tempFilters);
			delete currentSelectedFilters[filterName][selectedItemKey];
			setTempFilters(currentSelectedFilters);
		}
	};

	return (
		<>
			<div className="flex justify-between ">
				<span className="text-color-primary font-weight-normal text-sm">Filter by</span>
				<span onClick={() => setFilterOpen(false)}>{<img src={CloseSvg} className="h-5 cursor-pointer" alt="close-button" />}</span>
			</div>

			{filters.map((filter, index) => {
				return (
					<div>
						{
							<div
								className="px-4 flex justify-between my-3 text-color-secondary cursor-pointer"
								onClick={() =>
									// toggling filter list
									setFilterListOpen(filterListOpen === filter.key ? "" : filter.key)
								}
							>
								<span className="text-sm self-center" filter-key={filter.key}>
									{filter.title}
								</span>
								<img src={UpArrowSvg} alt="" className={`h-5 self-center  ${filterListOpen !== filter.key ? "transform rotate-90" : "transform rotate-180"}`} />
							</div>
						}

						{filterListOpen === filter.key ? (
							<div className="bg-background p-4 rounded-md overflow-auto max-h-40">
								{Object.keys(filter.options).map((optionKey, index) => {
									const isSelected = tempFilters[filter.key]?.hasOwnProperty(optionKey);
									return (
										<div className="w-full overflow-auto" key={index}>
											<label key={index} filter-item-key={optionKey}>
												<CheckboxSvg fill={isSelected ? "blue" : ""} stroke={isSelected ? "blue" : ""} svgClass="inline" />
												<input type="checkbox" className="hidden" checked={isSelected} onChange={() => handleFilterSelect(filter.key, optionKey, filter.options[optionKey])} />
												<span className="ml-2 text-sm text-gray-500 cursor-pointer">‍ {filter.options[optionKey]}‍</span>
											</label>
										</div>
									);
								})}
							</div>
						) : (
							""
						)}
					</div>
				);
			})}

			<div className="flex justify-end mt-4">
				<button
					className="bg-primary-accent-color rounded-md px-10 py-2 text-white text-xs font-weight-normal"
					onClick={(e) => {
						e.preventDefault();
						setFilterOpen(false);
						setActiveFilters(tempFilters);
					}}
				>
					Filter
				</button>
			</div>
		</>
	);
};

export default FilterPopup;

import React from "react";
import { Helmet } from "react-helmet-async";

const PageMeta = ({ title, description }) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta charset="utf-8" />
			<link rel="icon" href="/assets/images/favicon.ico" />
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<meta name="theme-color" content="#000000" />
			<meta name="author" content="Smart Software Testing Solutions Inc. US." />
			<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
			<link rel="apple-touch-icon" href="/logo.png" />
		</Helmet>
	);
};

export default PageMeta;

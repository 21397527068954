import { EventSourcePolyfill } from "event-source-polyfill";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const AUTH_COOKIE_NAME = "PYPCLOUDY";
function sseSubscribe(url, headers = {}, legacy = true) {
	const options = {
		withCredentials: true,
		headers: {
			Authorization: `Session ${cookies.get(AUTH_COOKIE_NAME)}`,
			...headers
		}
	};
	if (legacy === false) {
		return new EventSource(url, options);
	} else {
		return new EventSourcePolyfill(url, options);
	}
}
export { sseSubscribe };

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import ShareIcon from "../../../assets/images/shareimage.png";
import Breadcrumbs from "../../../components/breadcrumbs";
import toast from "react-hot-toast";
import sessionController from "../controllers/session-controller";
import EditSessionModal from "../../../components/EditSessionModal";

export const SubHeader = ({ sessionName, sessionId, buildName, buildId, backgroundClass, boxShadow, rounded, setDelete, sessionStatus }) => {
	let history = useHistory();
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const isEditingEnabled = sessionStatus !== "in-progress";
	const [isHovered, setIsHovered] = useState(false);

	const handleEditButtonClick = () => {
		if (isEditingEnabled) {
			setIsEditModalOpen(true);
		}
	};

	const handleUpdateSession = (updatedData) => {
		sessionController
			.updateSessionDetails(sessionId, updatedData)
			.then((resp) => {})
			.catch((error) => {
				console.error("Error updating session details:", error);
			});
	};

	return (
		<div className={backgroundClass + (boxShadow === true ? " shadow-primary" : "") + (rounded === true ? " rounded-md" : "")}>
			<div className="space-y-4 mx-auto flex flex-wrap py-6 flex-col md:flex-row items-center justify-between">
				<div>
					<button onClick={() => history.goBack()} className="text-xs text-color-secondary block pb-2">
						&lt; Back
					</button>
					<div className="flex">
						<Breadcrumbs
							crumbs={[
								{ name: buildName, id: buildId, type: "build" },
								{ name: sessionName, id: sessionId, type: "session" }
							]}
						/>
						<div className="relative">
							<button
								id="editButton"
								data-tooltip-target="tooltip-light"
								className={`mt-2 transform hover:-translate-y-px ${sessionStatus === "in-progress" ? "opacity-50" : ""}`}
								onClick={handleEditButtonClick}
								onMouseEnter={() => setIsHovered(true)}
								onMouseLeave={() => setIsHovered(false)}
							>
								<MdOutlineEdit size={32} className="ml-4 text-blue-800 rounded-md p-1" />
							</button>
							{sessionStatus === "in-progress" && isHovered && (
								<div
									id="tooltip-light"
									role="tooltip"
									className="absolute z-10 inline-block px-5 mr-10 mt-8 py-4 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100 tooltip"
									style={{ left: "-20px" }}
								>
									<div className="tooltip-arrow" data-popper-arrow></div>
									<span className="whitespace-nowrap">Editing is disabled when session 'in-progress'</span>
								</div>
							)}
						</div>
						<button
							className=" transform hover:-translate-y-px"
							onClick={() => {
								setDelete(true);
							}}
						>
							<MdOutlineDelete size={32} className="ml-1  text-red-600 rounded-md p-1" />
						</button>
					</div>
				</div>

				<div className="flex space-x-2 relative">
					{/* <button
                        type="button"
                        className="md:ml-auto py-2 px-4 flex justify-center items-center rounded-md text-sm bg-foreground shadow-primary"
                        onClick={() => {

                        }}
                    >
                        <FaBug size={20} className="mr-2 text-red-600" />
                        Log a Bug
                        <FaChevronDown size={12} className="ml-3 mt-1" />
                    </button> */}
					<button
						type="button"
						className=" md:ml-auto py-2 px-4 flex justify-center items-center rounded-md text-sm bg-foreground text-color-primary shadow-primary"
						title="Anyone with this link can view this Session"
						onClick={() => {
							sessionController
								.getSharableLink(sessionId)
								.then((data) => {
									const sharedUrl = window.location.origin + `/shared/session/${sessionId}?sharetoken=${data.data.token}`;
									navigator.clipboard
										.writeText(sharedUrl)
										.then(() => {
											toast.success("Link Copied Successfully");
										})
										.catch((e) => {
											console.log(e);
											toast.error("Something went wrong");
										});
								})
								.catch((err) => {
									toast.error("Something went wrong");
								});
						}}
					>
						<img src={ShareIcon} className="h-4 mr-2" id="shareimage" alt="Share" />
						Share Link
					</button>
				</div>
			</div>
			<EditSessionModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} handleUpdateSession={handleUpdateSession} sessionName={sessionName} sessionStatus={sessionStatus} />
		</div>
	);
};
